'use strict'

import http from './http-common'
import authHeader from './auth-header'
import useSWR, { mutate, useSWRConfig } from 'swr'

const getAllTags = () => {
  return http.axiosClient.get(`/tags`, { headers: authHeader() })
}

export default {
  getAllTags
}
