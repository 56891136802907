'use strict'

import http from './http-common'
import authHeader from './auth-header'
import useSWR, { mutate, useSWRConfig } from 'swr'

const findAll = (registryId) => {
  const url = '/events'
  const { data, error } = useSWR([url, { headers: authHeader() }], http.fetcherAuth)
  return {
    data: data || [],
    isLoading: !error && !data,
    error: error
  }
}

const findByRegistry = (registryId) => {
  const url = `/registries/${registryId}/events`
  const { data, error } = useSWR([url, { headers: authHeader() }], http.fetcherAuth)
  return {
    data: data || [],
    isLoading: !error && !data,
    error: error
  }
}

const findByProject = (projectId) => {
  const url = `/projects/${projectId}/events`
  const { data, error } = useSWR([url, { headers: authHeader() }], http.fetcherAuth)
  return {
    data: data || [],
    isLoading: !error && !data,
    error: error
  }
}

const findByInterval = (start, end) => {
  return http.axiosClient.get(`/events?start=${start}&end=${end}`, {
    headers: authHeader()
  })
}

const findById = (registryId, id) => {
  return http.axiosClient.get(`/events/${id}`, {
    headers: authHeader()
  })
}

const create = (registryId, data) => {
  return http.axiosClient.post(`/registries/${registryId}/events`, data, {
    headers: authHeader()
  })
}

const update = (registryId, id, data) => {
  return http.axiosClient.patch(`/events/${id}`, data, {
    headers: authHeader()
  })
}

const remove = (registryId, id) => {
  return http.axiosClient.delete(`/events/${id}`, {
    headers: authHeader()
  })
}
const refresh = (registryId) => {
  const url = registryId ? `/registries/${registryId}/events` : '/events'
  mutate([url, {
    headers: authHeader()
  }])
}

const upload = (data, projectId) => {
  return http.axiosClient.post('/events/upload', data, { headers: authHeader() })
}

const download = (file, projectId) => {
  return http.axiosClient.get(`/events/download/${file}`, { headers: authHeader() })
}

export default {
  findAll,
  findByRegistry,
  findByProject,
  findByInterval,
  findById,
  create,
  update,
  remove,
  refresh,
  upload,
  download
}
