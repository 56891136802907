'use strict'

import http from './http-common'
import authHeader from './auth-header'
import useSWR from 'swr'
import utils from 'utils/utils.js'

const findAll = ({ attributes, include } = {}) => {
  return http.axiosClient.get('/users', { headers: authHeader() })
  // const queryStringSlices = []
  // if (attributes) {
  //   queryStringSlices.push(attributes.map(attribute => `attributes=${attribute}`)
  //     .join('&'))
  // }
  // if (include) {
  //   queryStringSlices.push(include.map(child => `include=${child}`)
  //     .join('&'))
  // }

  // const queryString = !utils.isEmpty(queryStringSlices)
  //   ? '?' + queryStringSlices.join('&')
  //   : ''
  // return http.axiosClient.get('/users' + queryString, { headers: authHeader() })
}

const findById = id => {
  return http.axiosClient.get(`/users/${id}`, { headers: authHeader() })
}

const create = data => {
  return http.axiosClient.post('/users', data, { headers: authHeader() })
}

const update = (id, data) => {
  return http.axiosClient.put(`/users/${id}`, data, { headers: authHeader() })
}

const updateProfile = (id, data) => {
  return http.axiosClient.put(`/users/profile/${id}`, data, {
    headers: authHeader()
  })
}

const remove = id => {
  return http.axiosClient.delete(`/users/${id}`, { headers: authHeader() })
}

const findByRegistry = registryId => {
  return http.axiosClient.get(`/users?registryId=${registryId}`, {
    headers: authHeader()
  })
}

const findByEmail = email => {
  return http.axiosClient.get(`/users?email=${email}`)
}

const findByRole = role => {
  // accetta un array di ruoli
  return http.axiosClient.get(`/users/byRole/${role}`, {
    headers: authHeader()
  })
}

const findWorkers = () => {
  const url = '/users/workers'
  const { data, error } = useSWR(
    [url, { headers: authHeader() }],
    http.fetcherAuth
  )
  return {
    data: data || [],
    isLoading: !error && !data,
    error: error
  }
}

export default {
  findAll,
  findById,
  create,
  update,
  updateProfile,
  remove,
  findByRegistry,
  findByEmail,
  findByRole,
  findWorkers
}
