import { create } from 'zustand'

const useStore = create((set) => ({
  count: 0,
  test: false,
  increaseCount: () => set((state) => ({ count: state.count + 1 })),
  decreaseCount: () => set((state) => ({ count: state.count - 1 })),
  setTest: (input) => set((state) => ({ test: input }))
}))

// when invoked it will remove subscriber
const unsubscribe = useStore.subscribe(
  (newValue, oldValue) => {
    // console.log('oldValue: ' + oldValue)
    // console.log('newValue: ' + newValue)
  }, (state) => state.count
)

export { useStore }
