'use strict'

import http from './http-common'
import authHeader from './auth-header'
import useSWR from 'swr'

const findAll = () => {
  return http.axiosClient.get('/devicemodels', { headers: authHeader() })
}

const findById = (id) => {
  return http.axiosClient.get(`/devicemodels/${id}`, { headers: authHeader() })
}

const create = (data) => {
  return http.axiosClient.post('/devicemodels', data, { headers: authHeader() })
}

const update = (id, data) => {
  return http.axiosClient.patch(`/devicemodels/${id}`, data, {
    headers: authHeader()
  })
}

const remove = (id) => {
  return http.axiosClient.delete(`/devicemodels/${id}`, { headers: authHeader() })
}

export default {
  findAll,
  findById,
  create,
  update,
  remove
}
