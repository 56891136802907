import React, { useEffect } from 'react'
// @material-ui/core components
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import FormControlLabel from '@mui/material/FormControlLabel'

// Material Dashboard 2 PRO React components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'
import MDSnackbar from 'components/MDSnackbar'

// zerouno
import enumerationService from 'services/enumeration.service'
import { useForm, Controller } from 'react-hook-form'
import { useParams, Link, useNavigate } from 'react-router-dom'

export default function EnumerationForm () {
  const { idObj } = useParams()
  const [obj, setObj] = React.useState({})
  const [showSnackbar, setShowSnackbar] = React.useState({
    sh: false,
    msg: '',
    color: 'success'
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    reset
  } = useForm()
  const navigate = useNavigate()
  // const { data: obj, isLoading: loadEnum, error: errEnums } = enumerationService.get(idObj)

  useEffect(() => {
    fetchObject()
  }, [])

  useEffect(() => {
    reset({ ...obj })
  }, [obj])

  const fetchObject = () => {
    if (idObj === 'new') {
      setObj({ order: '0' })
    } else {
      enumerationService.get(idObj).then(
        response => {
          const obj = response.data
          obj.order = '' + obj.order
          setObj(obj)
        },
        error => {
          setShowSnackbar({
            sh: true,
            msg: error.response
              ? error.response.data.message
              : 'errore di ricezione dati',
            color: 'error'
          })
        }
      )
    }
  }

  const onSubmit = data => {
    if (idObj === 'new') {
      addObj(data)
    } else {
      data.id = obj.id
      updateObj(data, obj.id)
    }
  }

  // Add obj
  const addObj = data => {
    // data.image = obj.image
    enumerationService.create(data).then(
      response => {
        setShowSnackbar({
          sh: true,
          msg: 'operazione effettuata con successo',
          color: 'success'
        })
        navigate('/enumerations')
      },
      error => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  const updateObj = (data, id) => {
    // data.image = obj.image
    enumerationService.update(id, data).then(
      response => {
        setShowSnackbar({
          sh: true,
          msg: 'operazione effettuata con successo',
          color: 'success'
        })
        navigate('/enumerations')
      },
      error => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={8}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Card>
              <MDBox p={2}>
                <Grid container spacing={2}>
                  <Grid item xs='auto'>
                    {/* <MDAvatar
                      src={obj.image ? config.SERVER_URL + '/download/' + obj.image : avatar}
                      alt='profile-image'
                      size='md'
                      shadow='md'
                    /> */}
                  </Grid>
                  <Grid item xs={8}>
                    <MDTypography fontWeight='regular'>
                      Descrizione
                    </MDTypography>
                    <MDTypography variant='subtitle2'>
                      {obj.description}
                    </MDTypography>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox p={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12}>
                    <MDInput
                      fullWidth
                      type='text'
                      required
                      autoFocus
                      label='descrizione'
                      error={!!errors.description}
                      {...register('description', { required: true })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDInput
                      fullWidth
                      type='text'
                      required
                      label='tipo'
                      error={!!errors.type}
                      {...register('type', { required: true })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDInput
                      fullWidth
                      type='number'
                      required
                      label='ordine'
                      error={!!errors.order}
                      {...register('order', { required: true })}
                    />
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox p={2}>
                <MDButton
                  variant='gradient'
                  color='secondary'
                  component={Link}
                  to='/enumerations/'
                >
                  Indietro
                </MDButton>
                <MDButton
                  type='submit'
                  variant='gradient'
                  color='success'
                  sx={{ ml: 1 }}
                >
                  Salva
                </MDButton>
              </MDBox>
            </Card>
          </form>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Card>
            <MDBox p={2}>
              {/* <MDAvatar
                src={obj.image ? config.SERVER_URL + '/download/' + obj.image : avatar}
                alt='profile-image'
                size='xl'
                shadow='sm'
              /> */}
            </MDBox>
            <MDBox p={2}>
              <MDTypography variant='h6'>Descrizione</MDTypography>
              <MDTypography variant='h4'>{obj.description}</MDTypography>
            </MDBox>
          </Card>
        </Grid>
      </Grid>
      <MDBox mb={2} />
      <Footer />

      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content={showSnackbar.msg}
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />
    </DashboardLayout>
  )
}
