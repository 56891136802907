'use strict'

import React, { useEffect } from 'react'
// @material-ui/core components
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'

// Material Dashboard 2 PRO React components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
// import MDTypography from 'components/MDTypography'
import MDSnackbar from 'components/MDSnackbar'

// zerouno
import deviceModelService from 'services/device-model.service'
import enumerationService from 'services/enumeration.service'

import { useForm, Controller } from 'react-hook-form'
import { useParams, Link, useNavigate } from 'react-router-dom'
import ZUTitleForm from 'components/ZeroUno/ZUTitleForm'
import utils from 'utils/utils.js'
import { config } from 'variables/Config.js'
import { Autocomplete } from '@mui/material'
import ZUAutoComplete from 'components/ZeroUno/ZUAutoComplete'

export default function DeviceModelForm() {
  const { idObj } = useParams()
  const [obj, setObj] = React.useState({})
  const [showSnackbar, setShowSnackbar] = React.useState({
    sh: false,
    msg: '',
    color: 'success'
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue
  } = useForm({
    typeId: '',
    type: null
  })
  const navigate = useNavigate()
  const {
    data: enums,
    isLoading: loadEnum,
    error: errEnums
  } = enumerationService.findByType('deviceType')

  useEffect(() => {
    fetchObject()
  }, [])

  useEffect(() => {
    reset({ ...obj })
  }, [obj])

  const fetchObject = () => {
    if (idObj !== 'new') {
      deviceModelService.findById(idObj).then(
        response => {
          setObj(response.data)
        },
        error => {
          setShowSnackbar({
            sh: true,
            msg: error.response
              ? error.response.data.message
              : 'errore di ricezione dati',
            color: 'error'
          })
        }
      )
    }
  }

  const onSubmit = data => {
    data.typeId = data.type.id
    if (idObj === 'new') {
      addObj(data)
    } else {
      data.id = obj.id
      updateObj(data, obj.id)
    }
  }

  // Add obj
  const addObj = data => {
    // data.image = obj.image
    deviceModelService.create(data).then(
      response => {
        setShowSnackbar({
          sh: true,
          msg: 'operazione effettuata con successo',
          color: 'success'
        })
        navigate('/device-models')
      },
      error => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  const updateObj = (data, id) => {
    // data.image = obj.image
    deviceModelService.update(id, data).then(
      response => {
        setShowSnackbar({
          sh: true,
          msg: 'operazione effettuata con successo',
          color: 'success'
        })
        navigate('/device-models')
      },
      error => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Card>
                <ZUTitleForm
                  bgColor='info'
                  title='Modello apparato'
                  icon='place'
                />

                <MDBox p={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                      <ZUAutoComplete
                        control={control}
                        label='tiplogia'
                        name='type'
                        options={enums}
                        defaultValue={obj.type || null}
                        error={!!errors.type}
                        required
                        rules={{ required: true }}
                        getOptionLabel={item => item.description}
                        getOptionKey={item => item.id}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        label='Nome'
                        error={!!errors.name}
                        {...register('name', { required: true })}
                      />
                    </Grid>
                  </Grid>
                </MDBox>
                <MDBox p={2}>
                  <MDButton
                    variant='gradient'
                    color='secondary'
                    component={Link}
                    to='/device-models'
                  >
                    Indietro
                  </MDButton>
                  <MDButton
                    type='submit'
                    variant='gradient'
                    color='success'
                    sx={{ ml: 1 }}
                  >
                    Salva
                  </MDButton>
                </MDBox>
              </Card>
            </form>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content={showSnackbar.msg}
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />
    </DashboardLayout>
  )
}
