import { useEffect, useContext, useState, useMemo } from 'react'

// @mui material components
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Switch from '@mui/material/Switch'
import Icon from '@mui/material/Icon'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDSnackbar from 'components/MDSnackbar'

// Material Dashboard 2 PRO React example components
import Footer from 'components/Footer'
import ComplexStatisticsCard from 'components/Cards/StatisticsCards/ComplexStatisticsCard'
import ZuDataTable from 'components/ZeroUno/ZuDataTable'

// zerouno
import troubleTicketsService from 'services/troubleTicket.service'
import { Link, useNavigate } from 'react-router-dom'
import ZUTitleForm from 'components/ZeroUno/ZUTitleForm'
import utils from 'utils/utils.js'
import { DateTime, Interval } from 'luxon'
import PageLayout from 'components/LayoutContainers/PageLayout'

// Images
// import bgImage from 'assets/images/bg-sign-in-basic.jpeg'
import bgImage from 'assets/images/zerounorabbit/bgComunica.jpg'
import logo from 'assets/images/zerounorabbit/logo.png'

function PublicDashboard() {
  const [showSnackbar, setShowSnackbar] = useState({
    sh: false,
    msg: '',
    color: 'success'
  })
  const [tickets, setTickets] = useState([])
  const [countOpen, setCountOpen] = useState(0)
  const [countNew, setCountNew] = useState(0)
  const [countExp, setCountExp] = useState(0)
  const navigate = useNavigate()

  useEffect(() => {
    initTickets()
    const intervalId = setInterval(() => {
      initTickets()
    }, 1000 * 5)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  const initTickets = () => {
    troubleTicketsService.findPublic().then(
      response => {
        setTickets(response.data)
        return response.data
      }
    ).then((tt) => {
      const ttn = tt.filter(item => item.stateId === 1)
      setCountNew(ttn.length)
      const tto = tt.filter(item => item.stateId === 2)
      setCountOpen(tto.length)
      const n = Date.now()
      setCountExp(
        [...ttn, ...tto].filter(
          item => {
            return (
              item.expiryDate &&
              DateTime.fromISO(item.expiryDate).toMillis() <
              DateTime.now().toMillis()
            )
          }
        ).length
      )
    })
  }

  const columns = [
    {
      header: 'numero',
      accessorKey: 'id'
    },
    {
      header: 'stato',
      accessorFn: d => {
        return d.state?.description === 'nuovo'
          ? (
            <>
              <Icon color='error'>circle</Icon> nuovo
            </>
          )
          : (
            d.state?.description
          )
      }
    },
    {
      header: 'cliente',
      accessorFn: d => {
        return d.registry.businessName.trim()
      }
    },
    {
      header: 'oggetto',
      accessorKey: 'subject'
    },
    {
      header: 'modificato',
      accessorFn: d => {
        return utils.formatDate(d.updatedAt, 'dd/MM/yyyy HH:mm:ss')
      }
    },
    {
      header: 'assegnato a',
      accessorFn: d => {
        return d.toUser ? d.toUser.surname + ' ' + d.toUser.name : ''
      }
    },
    {
      header: 'scadenza',
      accessorFn: d => {
        return d.expiryDate
          ? utils.formatDate(d.expiryDate, 'dd/MM/yyyy HH:mm:ss')
          : ''
      }
    }
  ]

  return (
    <PageLayout image={bgImage}>
      <MDBox mt={6}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color='info'
                icon='leaderboard'
                title='ticket nuovi'
                count={countNew}
                percentage={{
                  color: 'success',
                  amount:
                    ((countNew / (countNew + countOpen)) * 100).toFixed(2) +
                    '% ',
                  label: ' sul totale'
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color='success'
                icon='leaderboard'
                title='ticket aperti'
                count={countOpen}
                percentage={{
                  color: 'success',
                  amount:
                    ((countOpen / (countNew + countOpen)) * 100).toFixed(2) +
                    '% ',
                  label: ' sul totale'
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color='error'
                icon='leaderboard'
                title='ticket scaduti'
                count={countExp}
                percentage={{
                  color: 'success',
                  amount:
                    ((countExp / (countNew + countOpen)) * 100).toFixed(2) +
                    '% ',
                  label: ' sul totale'
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} xl={12} sx={{ height: 'max-content' }}>
            <Card>
              <ZUTitleForm
                bgColor='info'
                title='Trouble Tickets'
                icon='event'
              />

              <MDBox p={3} lineHeight={1}>
                <MDButton
                  color='info'
                  component={Link}
                  to='/troubletickets/new'
                  m={1}
                >
                  nuovo
                </MDButton>
              </MDBox>
              <ZuDataTable table={{ columns: columns, rows: tickets }} />
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mt={6} mb={6} sx={{ height: 500 }} />

      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content={showSnackbar.msg}
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />

      <Footer />
    </PageLayout>
  )
}

export default PublicDashboard
