import React, { useEffect } from 'react'
// @material-ui/core components
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'

// @material-ui/icons
import PermIdentityIcon from '@mui/icons-material/PermIdentity'
import AddAlertIcon from '@mui/icons-material/AddAlert'

// Material Dashboard 2 PRO React components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'
import MDSnackbar from 'components/MDSnackbar'
import MDAvatar from 'components/MDAvatar'

// zerouno
import avatar from 'assets/images/avatar.png'
import { config } from 'variables/Config.js'
import userService from 'services/user.service'
import uploadService from 'services/upload.service'
import authService from 'services/auth.service'
import { useForm, Controller } from 'react-hook-form'

export default function UserProfile () {
  const loggedUser = authService.user()
  const [obj, setObj] = React.useState(loggedUser)
  const [tr, setTR] = React.useState(false)
  const [message, setMessage] = React.useState('')
  const [messageClass, setMessageClass] = React.useState('success')
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues
  } = useForm()

  useEffect(() => {
    fetchObject()
  }, [])

  const fetchObject = () => {
    setObj(loggedUser)
    // userService.findById(loggedUser.id).then(
    //   (response) => {
    //     setObj(response.data)
    //   },
    //   (error) => {
    //     if (error.response) {
    //       console.error(error.response.data.message)
    //       showNotification(error.response.data.message, 'error')
    //     } else {
    //       showNotification('errore di ricezione dati')
    //     }
    //   }
    // )
  }

  const onSubmit = data => {
    data.id = obj.id
    updateObj(data, obj.id)
  }

  const updateObj = (data, id) => {
    data.image = obj.image
    userService.updateProfile(id, data).then(
      response => {
        showNotification('operazione effettuata con sussesso', 'success')
        fetchObject()
      },
      error => {
        if (error.response) {
          console.error(error.response.data.message)
          showNotification(error.response.data.message, 'error')
        } else {
          showNotification('errore di ricezione dati', 'danger')
        }
      }
    )
  }

  const uploadFile = files => {
    const file = files[0]
    if (file) {
      if (file.size <= 1024000) {
        const formData = new FormData()
        formData.append('file', file)
        uploadService.uploadOne(formData).then(
          response => {
            const image = response.data.filename
            setObj({ ...obj, image: image })
            // showNotification('operazione effettuata con sussesso', 'success')
          },
          error => {
            if (error.response) {
              console.error(error.response.data.message)
              showNotification(error.response.data.message, 'error')
            } else {
              showNotification('errore di ricezione dati', 'error')
            }
          }
        )
      } else {
        showNotification('file troppo grande', 'error')
      }
    }
  }

  const removeFile = () => {
    const formData = new FormData()
    formData.delete('file')
    setObj({ ...obj, image: '' })
  }

  const showNotification = (message, messageClass) => {
    if (!tr) {
      setTR(true)
      setTimeout(function () {
        setTR(false)
      }, 6000)
      setMessageClass(messageClass)
      setMessage(message)
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={8}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Card>
              <MDBox p={2}>
                <Grid container spacing={2}>
                  <Grid item xs='auto'>
                    <MDAvatar
                      src={
                        loggedUser.image
                          ? config.SERVER_URL + '/download/' + loggedUser.image
                          : avatar
                      }
                      alt='profile-image'
                      size='md'
                      shadow='md'
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <MDTypography fontWeight='regular'>
                      {loggedUser.email}
                    </MDTypography>
                    <MDTypography variant='subtitle2'>
                      {loggedUser.name} {loggedUser.surname}
                    </MDTypography>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox p={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDInput
                      fullWidth
                      type='text'
                      label='nome'
                      defaultValue={obj.name}
                      required
                      autoFocus
                      error={!!errors.name}
                      {...register('name', { required: true })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDInput
                      fullWidth
                      type='text'
                      label='cognome'
                      defaultValue={obj.surname}
                      required
                      error={!!errors.surname}
                      {...register('surname', { required: true })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDInput
                      fullWidth
                      type='email'
                      label='email'
                      defaultValue={obj.email}
                      required
                      error={!!errors.email}
                      {...register('email', {
                        required: true,
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: 'mail non valida'
                        }
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} />
                  <Grid item xs={12} sm={6} md={6}>
                    <MDInput
                      fullWidth
                      type='password'
                      label='Password'
                      error={!!errors.password}
                      autoComplete='new-password'
                      {...register('password')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDInput
                      fullWidth
                      type='password'
                      label='confirm password'
                      defaultValue=''
                      error={!!errors.confirmPassword}
                      autoComplete='off'
                      {...register('confirmPassword', {
                        validate: () =>
                          getValues('confirmPassword') === getValues('password')
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <MDInput
                      fullWidth
                      type='text'
                      required={false}
                      label='telefono'
                      {...register('phone', {
                        required: false
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <MDInput
                      fullWidth
                      type='text'
                      required={false}
                      label='cellulare'
                      {...register('mobile', {
                        required: false
                      })}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={4}>
                    {(obj.image === null ||
                      obj.image === undefined ||
                      obj.image === '') && (
                      <label htmlFor='contained-button-file'>
                        <MDInput
                          accept='image/*'
                          id='contained-button-file'
                          type='file'
                          sx={{ display: 'none' }}
                          onChange={target => uploadFile(target.target.files)}
                        />
                        <MDButton
                          variant='gradient'
                          color='info'
                          component='span'
                        >
                          Upload immagine profilo
                        </MDButton>
                      </label>
                    )}
                    {obj.image && (
                      <>
                        <img
                          src={config.SERVER_URL + '/download/' + obj.image}
                          alt='immagine profilo'
                          width='160'
                          style={{ marginTop: '30px' }}
                        />
                        <MDButton color='error' onClick={() => removeFile()}>
                          elimina
                        </MDButton>
                      </>
                    )}
                  </Grid>
                  {/* <Grid item xs={6} sm={6} md={6}>
                      {(obj.image === null || obj.image === undefined || obj.image === '') && (
                        <DropzoneArea
                          acceptedFiles={['image/*']}
                          filesLimit={1}
                          maxFileSize={5120000}
                          dropzoneText={"Drag and drop un'immagine o clicca qui"}
                          dropzoneParagraphClass='dropzoneText'
                          showPreviews={false}
                          showPreviewsInDropzone={false}
                  // onAdd={(files) => uploadFile(files)}
                  // onDelete={(fileObj) => console.log('Removed File:', fileObj)}
                          onChange={(files) => uploadFile(files)}
                          style={{ marginTop: '30px' }}
                        />
                      )}
                      {obj.image && (
                        <>
                          <img
                            src={config.SERVER_URL + '/download/' + obj.image}
                            alt='immagine profilo'
                            width='160'
                            style={{ marginTop: '30px' }}
                          />
                          <MDButton color='danger' onClick={() => removeFile()}>
                            elimina
                          </MDButton>
                        </>
                      )}
                    </Grid> */}
                  <Grid item xs={12} sm={6} md={6} />
                </Grid>
              </MDBox>
              <MDBox p={2}>
                <MDButton type='submit' variant='gradient' color='success'>
                  Salva Profilo
                </MDButton>
              </MDBox>
            </Card>
          </form>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Card>
            <MDBox p={2}>
              <MDAvatar
                src={
                  loggedUser.image
                    ? config.SERVER_URL + '/download/' + loggedUser.image
                    : avatar
                }
                alt='profile-image'
                size='xl'
                shadow='sm'
              />
            </MDBox>
            <MDBox p={2}>
              <MDTypography variant='h6'>{loggedUser.email}</MDTypography>
              <MDTypography variant='h4'>
                {loggedUser.name} {loggedUser.surname}
              </MDTypography>
            </MDBox>
          </Card>
        </Grid>
      </Grid>
      <MDBox mb={2} />
      <Footer />

      <MDSnackbar
        color={messageClass}
        icon='notifications'
        title='Notifica'
        content={message}
        dateTime='adesso'
        open={tr}
        autoHideDuration={5000}
        close={() => setTR(false)}
      />
    </DashboardLayout>
  )
}
