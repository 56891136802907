import { DateTime } from 'luxon'

function isEmpty (value) {
  return (
    value === null || value === '' || value === undefined || value.length === 0
  )
}

function formatDate (value, pattern) {
  // TODO: Value tipo data, pattern tipo stringa "DD/MM/YYYY HH:mm"
  return DateTime.fromISO(value).toFormat(pattern)
}

const floatPattern = /^\d+,?\d*$/
const integerPattern = /^[0-9]+$/

function formatFloat (value, decimals) {
  if (value === null || value === '' || value === undefined) {
    value = 0.0
  }
  value = parseFloat(value.toFixed(decimals))
  return value.toLocaleString('it').replace('.', '')
}

function percentage (tot, num) {
  return ((num / tot) * 100).toFixed(0)
}

function percentageIncrement (tot, num) {
  return (((num - tot) / tot) * 100).toFixed(0)
}

const getObjectFromArray = (values, id) => {
  const obj = values && values.filter((item) => item.id == id)[0]
  if (obj) {
    return obj
  }
  return { id: 'n' }
}

const periodicities = [
  { id: 0, label: 'una tantum' },
  { id: 1, label: 'mensile' },
  { id: 2, label: 'bimestrale' },
  { id: 3, label: 'trimestrale' },
  { id: 6, label: 'semestrale' },
  { id: 12, label: 'annuale' }
]

const scheduleEnum = [
  {
    label: 'non ripetere',
    id: '1'
  },
  {
    label: 'ogni giorno',
    id: 'd'
  },
  {
    label: 'ogni settimana',
    id: 'w'
  },
  {
    label: 'ogni mese',
    id: 'm'
  },
  {
    label: 'ogni anno',
    id: 'y'
  }
]

function isValidTime (str) {
  // Regular expression to match the time format HH:mm
  const regex = /^([01][0-9]|2[0-3]):[0-5][0-9]$/

  return regex.test(str)
}

const time = () => {
  const hours = []
  for (let h = 6; h < 24; h++) {
    for (let m = 0; m < 60; m = m + 10) {
      hours.push({
        id: `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}`,
        label: `${h.toString().padStart(2, '0')}:${m
          .toString()
          .padStart(2, '0')}`
      })
    }
  }
  return hours
}

const province = [
  {
    nome: 'Agrigento',
    label: 'AG',
    regione: 'Sicilia'
  },
  {
    nome: 'Alessandria',
    label: 'AL',
    regione: 'Piemonte'
  },
  {
    nome: 'Ancona',
    label: 'AN',
    regione: 'Marche'
  },
  {
    nome: 'Arezzo',
    label: 'AR',
    regione: 'Toscana'
  },
  {
    nome: 'Ascoli Piceno',
    label: 'AP',
    regione: 'Marche'
  },
  {
    nome: 'Asti',
    label: 'AT',
    regione: 'Piemonte'
  },
  {
    nome: 'Avellino',
    label: 'AV',
    regione: 'Campania'
  },
  {
    nome: 'Bari',
    label: 'BA',
    regione: 'Puglia'
  },
  {
    nome: 'Barletta-Andria-Trani',
    label: 'BT',
    regione: 'Puglia'
  },
  {
    nome: 'Belluno',
    label: 'BL',
    regione: 'Veneto'
  },
  {
    nome: 'Benevento',
    label: 'BN',
    regione: 'Campania'
  },
  {
    nome: 'Bergamo',
    label: 'BG',
    regione: 'Lombardia'
  },
  {
    nome: 'Biella',
    label: 'BI',
    regione: 'Piemonte'
  },
  {
    nome: 'Bologna',
    label: 'BO',
    regione: 'Emilia-Romagna'
  },
  {
    nome: 'Bolzano/Bozen',
    label: 'BZ',
    regione: 'Trentino-Alto Adige/Südtirol'
  },
  {
    nome: 'Brescia',
    label: 'BS',
    regione: 'Lombardia'
  },
  {
    nome: 'Brindisi',
    label: 'BR',
    regione: 'Puglia'
  },
  {
    nome: 'Cagliari',
    label: 'CA',
    regione: 'Sardegna'
  },
  {
    nome: 'Caltanissetta',
    label: 'CL',
    regione: 'Sicilia'
  },
  {
    nome: 'Campobasso',
    label: 'CB',
    regione: 'Molise'
  },
  {
    nome: 'Carbonia-Iglesias',
    label: 'CI',
    regione: 'Sardegna'
  },
  {
    nome: 'Caserta',
    label: 'CE',
    regione: 'Campania'
  },
  {
    nome: 'Catania',
    label: 'CT',
    regione: 'Sicilia'
  },
  {
    nome: 'Catanzaro',
    label: 'CZ',
    regione: 'Calabria'
  },
  {
    nome: 'Chieti',
    label: 'CH',
    regione: 'Abruzzo'
  },
  {
    nome: 'Como',
    label: 'CO',
    regione: 'Lombardia'
  },
  {
    nome: 'Cosenza',
    label: 'CS',
    regione: 'Calabria'
  },
  {
    nome: 'Cremona',
    label: 'CR',
    regione: 'Lombardia'
  },
  {
    nome: 'Crotone',
    label: 'KR',
    regione: 'Calabria'
  },
  {
    nome: 'Cuneo',
    label: 'CN',
    regione: 'Piemonte'
  },
  {
    nome: 'Enna',
    label: 'EN',
    regione: 'Sicilia'
  },
  {
    nome: 'Fermo',
    label: 'FM',
    regione: 'Marche'
  },
  {
    nome: 'Ferrara',
    label: 'FE',
    regione: 'Emilia-Romagna'
  },
  {
    nome: 'Firenze',
    label: 'FI',
    regione: 'Toscana'
  },
  {
    nome: 'Foggia',
    label: 'FG',
    regione: 'Puglia'
  },
  {
    nome: 'Forlì-Cesena',
    label: 'FC',
    regione: 'Emilia-Romagna'
  },
  {
    nome: 'Frosinone',
    label: 'FR',
    regione: 'Lazio'
  },
  {
    nome: 'Genova',
    label: 'GE',
    regione: 'Liguria'
  },
  {
    nome: 'Gorizia',
    label: 'GO',
    regione: 'Friuli-Venezia Giulia'
  },
  {
    nome: 'Grosseto',
    label: 'GR',
    regione: 'Toscana'
  },
  {
    nome: 'Imperia',
    label: 'IM',
    regione: 'Liguria'
  },
  {
    nome: 'Isernia',
    label: 'IS',
    regione: 'Molise'
  },
  {
    nome: "L'Aquila",
    label: 'AQ',
    regione: 'Abruzzo'
  },
  {
    nome: 'La Spezia',
    label: 'SP',
    regione: 'Liguria'
  },
  {
    nome: 'Latina',
    label: 'LT',
    regione: 'Lazio'
  },
  {
    nome: 'Lecce',
    label: 'LE',
    regione: 'Puglia'
  },
  {
    nome: 'Lecco',
    label: 'LC',
    regione: 'Lombardia'
  },
  {
    nome: 'Livorno',
    label: 'LI',
    regione: 'Toscana'
  },
  {
    nome: 'Lodi',
    label: 'LO',
    regione: 'Lombardia'
  },
  {
    nome: 'Lucca',
    label: 'LU',
    regione: 'Toscana'
  },
  {
    nome: 'Macerata',
    label: 'MC',
    regione: 'Marche'
  },
  {
    nome: 'Mantova',
    label: 'MN',
    regione: 'Lombardia'
  },
  {
    nome: 'Massa-Carrara',
    label: 'MS',
    regione: 'Toscana'
  },
  {
    nome: 'Matera',
    label: 'MT',
    regione: 'Basilicata'
  },
  {
    nome: 'Medio Campidano',
    label: 'VS',
    regione: 'Sardegna'
  },
  {
    nome: 'Messina',
    label: 'ME',
    regione: 'Sicilia'
  },
  {
    nome: 'Milano',
    label: 'MI',
    regione: 'Lombardia'
  },
  {
    nome: 'Modena',
    label: 'MO',
    regione: 'Emilia-Romagna'
  },
  {
    nome: 'Monza e della Brianza',
    label: 'MB',
    regione: 'Lombardia'
  },
  {
    nome: 'Napoli',
    label: 'NA',
    regione: 'Campania'
  },
  {
    nome: 'Novara',
    label: 'NO',
    regione: 'Piemonte'
  },
  {
    nome: 'Nuoro',
    label: 'NU',
    regione: 'Sardegna'
  },
  {
    nome: 'Ogliastra',
    label: 'OG',
    regione: 'Sardegna'
  },
  {
    nome: 'Olbia-Tempio',
    label: 'OT',
    regione: 'Sardegna'
  },
  {
    nome: 'Oristano',
    label: 'OR',
    regione: 'Sardegna'
  },
  {
    nome: 'Padova',
    label: 'PD',
    regione: 'Veneto'
  },
  {
    nome: 'Palermo',
    label: 'PA',
    regione: 'Sicilia'
  },
  {
    nome: 'Parma',
    label: 'PR',
    regione: 'Emilia-Romagna'
  },
  {
    nome: 'Pavia',
    label: 'PV',
    regione: 'Lombardia'
  },
  {
    nome: 'Perugia',
    label: 'PG',
    regione: 'Umbria'
  },
  {
    nome: 'Pesaro e Urbino',
    label: 'PU',
    regione: 'Marche'
  },
  {
    nome: 'Pescara',
    label: 'PE',
    regione: 'Abruzzo'
  },
  {
    nome: 'Piacenza',
    label: 'PC',
    regione: 'Emilia-Romagna'
  },
  {
    nome: 'Pisa',
    label: 'PI',
    regione: 'Toscana'
  },
  {
    nome: 'Pistoia',
    label: 'PT',
    regione: 'Toscana'
  },
  {
    nome: 'Pordenone',
    label: 'PN',
    regione: 'Friuli-Venezia Giulia'
  },
  {
    nome: 'Potenza',
    label: 'PZ',
    regione: 'Basilicata'
  },
  {
    nome: 'Prato',
    label: 'PO',
    regione: 'Toscana'
  },
  {
    nome: 'Ragusa',
    label: 'RG',
    regione: 'Sicilia'
  },
  {
    nome: 'Ravenna',
    label: 'RA',
    regione: 'Emilia-Romagna'
  },
  {
    nome: 'Reggio di Calabria',
    label: 'RC',
    regione: 'Calabria'
  },
  {
    nome: "Reggio nell'Emilia",
    label: 'RE',
    regione: 'Emilia-Romagna'
  },
  {
    nome: 'Rieti',
    label: 'RI',
    regione: 'Lazio'
  },
  {
    nome: 'Rimini',
    label: 'RN',
    regione: 'Emilia-Romagna'
  },
  {
    nome: 'Roma',
    label: 'RM',
    regione: 'Lazio'
  },
  {
    nome: 'Rovigo',
    label: 'RO',
    regione: 'Veneto'
  },
  {
    nome: 'Salerno',
    label: 'SA',
    regione: 'Campania'
  },
  {
    nome: 'Sassari',
    label: 'SS',
    regione: 'Sardegna'
  },
  {
    nome: 'Savona',
    label: 'SV',
    regione: 'Liguria'
  },
  {
    nome: 'Siena',
    label: 'SI',
    regione: 'Toscana'
  },
  {
    nome: 'Siracusa',
    label: 'SR',
    regione: 'Sicilia'
  },
  {
    nome: 'Sondrio',
    label: 'SO',
    regione: 'Lombardia'
  },
  {
    nome: 'Taranto',
    label: 'TA',
    regione: 'Puglia'
  },
  {
    nome: 'Teramo',
    label: 'TE',
    regione: 'Abruzzo'
  },
  {
    nome: 'Terni',
    label: 'TR',
    regione: 'Umbria'
  },
  {
    nome: 'Torino',
    label: 'TO',
    regione: 'Piemonte'
  },
  {
    nome: 'Trapani',
    label: 'TP',
    regione: 'Sicilia'
  },
  {
    nome: 'Trento',
    label: 'TN',
    regione: 'Trentino-Alto Adige/Südtirol'
  },
  {
    nome: 'Treviso',
    label: 'TV',
    regione: 'Veneto'
  },
  {
    nome: 'Trieste',
    label: 'TS',
    regione: 'Friuli-Venezia Giulia'
  },
  {
    nome: 'Udine',
    label: 'UD',
    regione: 'Friuli-Venezia Giulia'
  },
  {
    nome: "Valle d'Aosta/Vallée d'Aoste",
    label: 'AO',
    regione: "Valle d'Aosta/Vallée d'Aoste"
  },
  {
    nome: 'Varese',
    label: 'VA',
    regione: 'Lombardia'
  },
  {
    nome: 'Venezia',
    label: 'VE',
    regione: 'Veneto'
  },
  {
    nome: 'Verbano-Cusio-Ossola',
    label: 'VB',
    regione: 'Piemonte'
  },
  {
    nome: 'Vercelli',
    label: 'VC',
    regione: 'Piemonte'
  },
  {
    nome: 'Verona',
    label: 'VR',
    regione: 'Veneto'
  },
  {
    nome: 'Vibo Valentia',
    label: 'VV',
    regione: 'Calabria'
  },
  {
    nome: 'Vicenza',
    label: 'VI',
    regione: 'Veneto'
  },
  {
    nome: 'Viterbo',
    label: 'VT',
    regione: 'Lazio'
  }
]

export default {
  isEmpty,
  formatDate,
  formatFloat,
  floatPattern,
  getObjectFromArray,
  integerPattern,
  percentage,
  percentageIncrement,
  province,
  scheduleEnum,
  periodicities,
  time,
  isValidTime
}
