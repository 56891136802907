'use strict'
import { useEffect, useState } from 'react'
// @mui material components
import Card from '@mui/material/Card'
import Icon from '@mui/material/Icon'
import Grid from '@mui/material/Grid'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import Stack from '@mui/material/Stack'
// import Menu from '@mui/material/Menu'
// import MenuItem from '@mui/material/MenuItem'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDSnackbar from 'components/MDSnackbar'
import MDButton from 'components/MDButton'

// Material Dashboard 2 PRO React example components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'

// zerouno
import newsletterService from 'services/newsletter.service'
import { useParams, Link } from 'react-router-dom'
import ZUTitleForm from 'components/ZeroUno/ZUTitleForm'
import RegistryNav from './RegistryNav'
import ZuDataTable from 'components/ZeroUno/ZuDataTable'
import utils from 'utils/utils.js'

export default function NewsletterTable () {
  const [objId, setObjId] = useState(0)
  const [objects, setObjects] = useState([])
  const [showSnackbar, setShowSnackbar] = useState({
    sh: false,
    msg: '',
    color: 'success'
  })
  const [openConfirm, setOpenConfirm] = useState(false)

  useEffect(() => {
    fetchObjects()
  }, [])

  const fetchObjects = () => {
    newsletterService.getAll().then(
      (response) => {
        setObjects(response.data)
      },
      (error) =>
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
    )
  }

  // Delete
  const deleteObject = () => {
    setOpenConfirm(false)
    newsletterService.remove(objId).then(
      (response) => {
        setShowSnackbar({
          sh: true,
          msg: 'operazione effettuata con successo',
          color: 'success'
        })
      },
      (error) => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  const columns = [
    {
      header: 'oggetto',
      accessorKey: 'subject'
    },
    {
      header: 'programmata per',
      accessorFn: d => {
        return utils.formatDate(d.scheduledOn, 'dd/MM/yyyy') + ' ' + d.scheduledOnTime
      }
    },
    {
      header: 'data invio',
      accessorFn: d => {
        return d.sentOn ? utils.formatDate(d.sentOn, 'dd/MM/yyyy HH:mm') : 'da inviare'
      }
    },
    {
      header: 'azioni',
      enableSorting: false,
      enableColumnFilter: false,
      size: 100,
      accessorKey: 'id',
      Cell: ({ cell }) => (
        <Grid container spacing={1}>
          <Grid item>
            <MDButton
              color='info'
              size='small'
              iconOnly
              component={Link}
              to={`/newsletters/${cell.getValue()}`}
            >
              <Icon>edit</Icon>
            </MDButton>
          </Grid>
          <Grid item>
            <MDButton
              iconOnly
              color='error'
              size='small'
              onClick={() => {
                setObjId(cell.getValue())
                setOpenConfirm(true)
              }}
            >
              <Icon>delete</Icon>
            </MDButton>
          </Grid>
        </Grid>
      )
    }
  ]

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <Card>
              <ZUTitleForm bgColor='info' title='Newsletters' icon='place' />
              <MDBox p={3} lineHeight={1}>
                <MDButton
                  color='secondary'
                  component={Link}
                  to='/newsletters'
                  sx={{ m: 1 }}
                >
                  indietro
                </MDButton>
                <MDButton
                  color='info'
                  component={Link}
                  to='/newsletters/new'
                  m={1}
                >
                  nuovo
                </MDButton>
              </MDBox>
              <ZuDataTable table={{ columns: columns, rows: objects }} />
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <Dialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          Confermi eliminazione?
        </DialogTitle>
        <DialogActions>
          <MDButton onClick={() => setOpenConfirm(false)}>Annulla</MDButton>
          <MDButton onClick={deleteObject} autoFocus>
            Conferma
          </MDButton>
        </DialogActions>
      </Dialog>
      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content={showSnackbar.msg}
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />
    </DashboardLayout>
  )
}
