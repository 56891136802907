'use strict'

import React, { useEffect } from 'react'
// @material-ui/core components
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import CircularProgress from '@mui/material/CircularProgress'
import Icon from '@mui/material/Icon'

// import Icon from '@mui/material/Icon'
import Autocomplete from '@mui/material/Autocomplete'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import { default as MuiLink } from '@mui/material/Link'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

// Material Dashboard 2 PRO React components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'
import MDSnackbar from 'components/MDSnackbar'
import TimelineList from 'components/Timeline/TimelineList'
import TimelineItem from 'components/Timeline/TimelineItem'

// zerouno
import troubleTicketService from 'services/troubleTicket.service'
import enumerationService from 'services/enumeration.service'
import registryService from 'services/registry.service'
import projectService from 'services/project.service'
import userService from 'services/user.service'
import authService from 'services/auth.service'
import { config } from 'variables/Config.js'
import { useForm, Controller } from 'react-hook-form'
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom'
import ZUTitleForm from 'components/ZeroUno/ZUTitleForm'
import RegistryNav from './RegistryNav'
import utils from 'utils/utils.js'

export default function TroubleTicketForm() {
  const { idObj } = useParams()
  const [obj, setObj] = React.useState({})
  const [mainObj, setMainObj] = React.useState({})
  const [registries, setRegistries] = React.useState([])
  const [showSnackbar, setShowSnackbar] = React.useState({
    sh: false,
    msg: '',
    color: 'success'
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    watch
  } = useForm()
  const [file, setFile] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const idRegistry = new URLSearchParams(useLocation().search).get('registryId')
  const {
    data: enums,
    isLoading: loadEnum,
    error: errEnums
  } = enumerationService.findByType('troubleTicket')
  const [users, setUsers] = React.useState([])
  const [projects, setProjects] = React.useState([])
  const [toUser, setToUser] = React.useState({})
  const [isLoadingSubmit, setIsLoadingSubmit] = React.useState(false)
  const { data: toUsers, isLoading, error } = userService.findWorkers()

  // const { data: registries, isLoading1, error1 } = registryService.findAll()
  const navigate = useNavigate()
  const watchRegistry = watch('registry')

  useEffect(() => {
    fetchObject()
    fetchRegistries()
    fetchCascade()
  }, [])

  useEffect(() => {
    reset({ ...obj })
    // setValue('expiryDate', obj.expiryDate ? DateTime.fromISO(obj.expiryDate).toLocal() : '')
  }, [obj, registries])

  useEffect(() => {
    fetchCascade(watchRegistry)
  }, [watchRegistry])

  const fetchObject = () => {
    if (idObj === 'new') {
      setObj({
        registryId: 'n',
        stateId: '1'
        // expiryDate: null
      })
    } else {
      troubleTicketService.findById(idObj).then(
        response => {
          const mainTicket = response.data
          setMainObj(mainTicket)
          setObj({
            registryId: mainTicket.registryId,
            registry: mainTicket.registry,
            subject: mainTicket.subject,
            description: '',
            stateId: '2',
            mainTicket: mainTicket.id
          })
          setToUser(mainTicket.toUser || {})
          return mainTicket
        }).then(
          (mainTicket) => {
            troubleTicketService.markRead(mainTicket.id)
          }).catch(
            error => showError(error)
          )
    }
  }

  const fetchRegistries = () => {
    if (authService.isAdmin()) {
      registryService.findAll().then(
        response => {
          setRegistries(response.data)
        },
        error => showError(error)
      )
    }
    if (authService.isUser()) {
      registryService.findByUser(authService.user().id).then(
        response => {
          setRegistries(response.data)
        },
        error => showError(error)
      )
    }
  }

  const fetchCascade = registry => {
    if (registry && registry.id) {
      userService.findByRegistry(registry.id).then(
        response => {
          setUsers(response.data.filter(item => item.active))
        },
        error => showError(error)
      )
      projectService.findByRegistry(registry.id).then(
        response => {
          setProjects(response.data)
        },
        error => showError(error)
      )
    } else {
      setUsers([])
      setProjects([])
    }
  }

  const showError = error => {
    setShowSnackbar({
      sh: true,
      msg: error.response
        ? error.response.data.message
        : 'errore di ricezione dati',
      color: 'error'
    })
  }
  const onSubmit = data => {
    data.file = file
    if (toUser.id) {
      data.toUser = toUser
      data.toUserId = toUser.id
    }
    // if (data.file === '') {
    //   setShowSnackbar({ sh: true, msg: 'inserire il file', color: 'error' })
    //   return
    // }
    if (idObj === 'new') {
      addObj(data)
    } else {
      data.registryId = mainObj.registryId
      data.registry = mainObj.registry
      data.project = mainObj.project
      data.projectId = mainObj.projectId
      data.subject = mainObj.subject
      data.mainTicket = mainObj
      data.mainTicketId = mainObj.id
      addObj(data)
      // updateObj(data, mainObj.id)
    }
  }

  // Add obj
  const addObj = data => {
    setIsLoadingSubmit(true)
    troubleTicketService.create(data, data.registryId).then(
      response => {
        setIsLoadingSubmit(false)
        setShowSnackbar({
          sh: true,
          msg: 'operazione effettuata con successo',
          color: 'success'
        })
        navigate('/troubletickets')
      },
      error => {
        setIsLoadingSubmit(false)
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  // const updateObj = (data, id) => {
  //   troubleTicketService.update(id, data).then(
  //     (response) => {
  //       setShowSnackbar({ sh: true, msg: 'operazione effettuata con successo', color: 'success' })
  //       navigate('/troubletickets')
  //     },
  //     (error) => {
  //       setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
  //     }
  //   )
  // }

  const uploadFile = files => {
    const newFile = files[0]
    if (newFile) {
      if (newFile.size <= 50 * 1024000) {
        setLoading(true)
        const formData = new FormData()
        formData.append('file', newFile)
        troubleTicketService.upload(formData).then(
          response => {
            setLoading(false)
            const filename = response.data
            setFile(filename)
          },
          error => {
            setLoading(false)
            setShowSnackbar({
              sh: true,
              msg: 'errore upload file ' + error.data.message,
              color: 'error'
            })
          }
        )
      } else {
        setShowSnackbar({ sh: true, msg: 'file troppo grande', color: 'error' })
      }
    }
  }

  const removeFile = () => {
    const formData = new FormData()
    formData.delete('file')
    setFile('')
  }

  const renderTimelineItems = () => {
    if (mainObj && mainObj.tickets) {
      const timelineItems = mainObj.tickets.map(
        ({
          id,
          subject,
          createdAt,
          description,
          user,
          userId,
          file,
          stateId
        }) => (
          <TimelineItem
            key={id}
            color={user.role === 'user' ? 'success' : 'info'}
            icon='label'
            title={
              user.name +
              ' ' +
              user.surname +
              (user.mobile ? ' | ' + user.mobile : '') +
              (user.phone ? ' | ' + user.phone : '')
            }
            dateTime={utils.formatDate(createdAt, 'dd/MM/yyyy HH:mm:ss')}
            description={description}
            file={file}
            lastItem={stateId === 3}
          />
        )
      )
      return timelineItems
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Card>
                <ZUTitleForm
                  bgColor='info'
                  title='Trouble Ticket'
                  icon='bug_report'
                />
                {mainObj.stateId !== 3 && (
                  <MDBox p={2}>
                    <Grid container spacing={2}>
                      {idObj === 'new' && (
                        <Grid item xs={12} sm={12} md={6}>
                          {obj.registryId && (
                            <Controller
                              name='registry'
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <Autocomplete
                                  options={registries}
                                  value={obj.registry}
                                  required
                                  onChange={(_, item) => {
                                    setObj({
                                      ...obj,
                                      registry: item,
                                      registryId: item ? item.id : 'n'
                                    })
                                    onChange(item ? item.id : null)
                                  }}
                                  getOptionLabel={item => item.businessName}
                                  isOptionEqualToValue={(item, val) => {
                                    return item.id == val.id
                                  }}
                                  renderInput={params => (
                                    <MDInput
                                      {...params}
                                      fullWidth
                                      required
                                      label='Azienda'
                                      error={!!errors.registry}
                                    />
                                  )}
                                />
                              )}
                            />
                          )}
                        </Grid>
                      )}
                      {idObj === 'new' && authService.isAdmin() && (
                        <Grid item xs={12} sm={12} md={4}>
                          <Controller
                            name='user'
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <Autocomplete
                                options={users}
                                value={obj.user}
                                onChange={(_, item) => {
                                  setObj({
                                    ...obj,
                                    user: item,
                                    userId: item ? item.id : 'n'
                                  })
                                  onChange(item ? item.id : null)
                                }}
                                getOptionLabel={item =>
                                  item.name + ' ' + item.surname}
                                isOptionEqualToValue={(item, val) => {
                                  return item.id == val.id
                                }}
                                renderInput={params => (
                                  <MDInput
                                    {...params}
                                    fullWidth
                                    label='Utente'
                                    error={!!errors.user}
                                  />
                                )}
                              />
                            )}
                          />
                        </Grid>
                      )}
                      {idObj === 'new' && (
                        <>
                          <Grid item xs={12} sm={12} md={4}>
                            <Controller
                              name='project'
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <Autocomplete
                                  options={projects}
                                  value={obj.project}
                                  onChange={(_, item) => {
                                    setObj({
                                      ...obj,
                                      project: item,
                                      projectId: item ? item.id : 'n'
                                    })
                                    onChange(item ? item.id : null)
                                  }}
                                  getOptionLabel={item => item.name}
                                  isOptionEqualToValue={(item, val) => {
                                    return item.id == val.id
                                  }}
                                  renderInput={params => (
                                    <MDInput
                                      {...params}
                                      fullWidth
                                      label='Servizio'
                                      error={!!errors.project}
                                    />
                                  )}
                                />
                              )}
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={9}>
                            <MDInput
                              fullWidth
                              type='text'
                              autoFocus
                              required
                              label='oggetto'
                              error={!!errors.subject}
                              {...register('subject', { required: true })}
                            />
                          </Grid>
                        </>
                      )}
                      <Grid item xs={12} sm={12} md={12}>
                        <MDInput
                          fullWidth
                          type='text'
                          multiline
                          rows={3}
                          required
                          label='descrizione'
                          {...register('description', { required: true })}
                        />
                      </Grid>
                      {!authService.isUser() && (
                        <Grid item xs={12} sm={12} md={8}>
                          <Controller
                            name='toUser'
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <Autocomplete
                                options={toUsers}
                                value={toUser}
                                onChange={(_, item) => {
                                  setToUser(item)
                                  onChange(item ? item.id : null)
                                }}
                                getOptionLabel={item =>
                                  item.id ? item.name + ' ' + item.surname : ''}
                                isOptionEqualToValue={(item, val) => {
                                  return item.id == val.id
                                }}
                                renderInput={params => (
                                  <MDInput
                                    {...params}
                                    fullWidth
                                    label='assegna a'
                                    error={!!errors.toUser}
                                  />
                                )}
                              />
                            )}
                          />
                        </Grid>
                      )}
                      <Grid item xs={12} sm={12} md={3}>
                        {obj.stateId && idObj !== 'new' && (
                          <FormControl
                            fullWidth
                            required
                            error={!!errors.stateId}
                          >
                            <InputLabel>stato</InputLabel>
                            <Select
                              label='stato'
                              defaultValue={obj.stateId}
                              {...register('stateId', { required: true })}
                            >
                              {enums &&
                                enums.map(item => {
                                  if (item.id !== 1) {
                                    return (
                                      <MenuItem key={item.id} value={item.id}>
                                        {item.description}
                                      </MenuItem>
                                    )
                                  }
                                })}
                            </Select>
                          </FormControl>
                        )}
                      </Grid>
                      <Grid item xs={12} />
                      <Grid item xs={12} sm={12} md={6}>
                        <Stack spacing={2}>
                          {(file === null ||
                            file === undefined ||
                            file === '') && (
                              <label htmlFor='contained-button-file'>
                                <MDInput
                                  accept='*'
                                  id='contained-button-file'
                                  type='file'
                                  sx={{ display: 'none' }}
                                  onChange={target =>
                                    uploadFile(target.target.files)}
                                />
                                <MDButton
                                  variant='gradient'
                                  color='info'
                                  component='span'
                                  style={{ marginTop: '30px' }}
                                >
                                  Upload documento (max 5Mb)
                                </MDButton>
                              </label>
                            )}
                          {loading && (
                            <CircularProgress color='primary' sx={{ m: 2 }} />
                          )}
                          {file && (
                            <MDButton
                              color='primary'
                              href={`${config.SERVER_URL}/troubletickets/download/${file}`}
                              target='_blank'
                              mb={2}
                            >
                              download
                            </MDButton>
                          )}
                          {file && (
                            <MDButton
                              color='error'
                              onClick={() => removeFile()}
                            >
                              elimina file
                            </MDButton>
                          )}
                        </Stack>
                      </Grid>
                    </Grid>
                  </MDBox>
                )}
                <MDBox p={2}>
                  <MDButton
                    variant='gradient'
                    color='secondary'
                    component={Link}
                    to='/troubletickets'
                  >
                    Indietro
                  </MDButton>
                  {mainObj.stateId !== 3 && (
                    <MDButton
                      type='submit'
                      variant='gradient'
                      color='success'
                      sx={{ ml: 1 }}
                      disabled={isLoadingSubmit}
                    >
                      Salva
                      {isLoadingSubmit && (
                        <CircularProgress
                          color='white'
                          style={{
                            width: '20px',
                            height: '20px',
                            marginLeft: '10px'
                          }}
                        />
                      )}
                    </MDButton>
                  )}
                </MDBox>
              </Card>
            </form>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Card>
              <ZUTitleForm
                bgColor='success'
                title='Trouble Ticket'
                icon='access_time'
              />
              {mainObj && mainObj.user && (
                <>
                  <MDTypography variant='h5' sx={{ mt: 1, mb: 0, ml: 2 }}>
                    Oggetto: {mainObj.subject}
                  </MDTypography>
                  <MDTypography
                    variant='h6'
                    color='info'
                    sx={{ mt: 0, mb: 1, ml: 2 }}
                  >
                    {utils.formatDate(mainObj.createdAt, 'dd/MM/yyyy HH:mm:ss')}
                  </MDTypography>
                  {mainObj.expiryDate && (
                    <MDTypography
                      variant='h6'
                      color='error'
                      sx={{ mt: 0, mb: 1, ml: 2 }}
                    >
                      {utils.formatDate(
                        mainObj.expiryDate,
                        'dd/MM/yyyy HH:mm:ss'
                      )}
                    </MDTypography>
                  )}

                  <MDTypography variant='h6' sx={{ mt: 0, mb: 1, ml: 2 }}>
                    {mainObj.user.name +
                      ' ' +
                      mainObj.user.surname +
                      (mainObj.user.mobile ? ' | ' + mainObj.user.mobile : '') +
                      (mainObj.user.phone
                        ? ' | ' + mainObj.user.phone
                        : '')}{' '}
                    - {mainObj.registry.businessName}
                  </MDTypography>
                  {mainObj.project && (
                    <MDTypography
                      variant='h6'
                      color='success'
                      sx={{ mt: 0, mb: 1, ml: 2 }}
                    >
                      Servizio:{' '}
                      <MuiLink
                        component={Link}
                        to={`/registries/${mainObj.registryId}/projects/${mainObj.projectId}`}
                        underline='always'
                      >
                        {mainObj.project.name}
                      </MuiLink>
                    </MDTypography>
                  )}
                  <MDTypography fontSize='0.8em' sx={{ mt: 0, mb: 1, ml: 2, whiteSpace: 'pre-line', lineHeight: '1.1em' }}>
                    {mainObj.description}
                  </MDTypography>
                  {mainObj.file && (
                    <MDButton
                      color='info'
                      variant='outlined'
                      size='small'
                      href={`${config.SERVER_URL}/troubletickets/download/${mainObj.file}`}
                      target='_blank'
                      sx={{ mt: 0, mb: 1, ml: 4, mr: 4 }}
                    >
                      <Icon>download</Icon>
                      download allegato
                    </MDButton>
                  )}
                </>
              )}
              {mainObj && mainObj.tickets && (
                <TimelineList title='History'>
                  {renderTimelineItems()}
                </TimelineList>
              )}
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={2}>
            {idRegistry && <RegistryNav id={idRegistry} />}
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content={showSnackbar.msg}
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />
    </DashboardLayout>
  )
}
