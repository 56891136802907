'use strict'

import http from './http-common'
import authHeader from './auth-header'

const findAll = (registryId) => {
  return http.axiosClient.get(registryId ? `/registries/${registryId}/orders` : '/orders', { headers: authHeader() })
}

const findById = (id) => {
  return http.axiosClient.get(`/orders/${id}`, {
    headers: authHeader()
  })
}

const create = (data) => {
  return http.axiosClient.post('/orders', data, {
    headers: authHeader()
  })
}

const update = (id, data) => {
  return http.axiosClient.patch(`/orders/${id}`, data, {
    headers: authHeader()
  })
}

const remove = (id) => {
  return http.axiosClient.delete(`/orders/${id}`, {
    headers: authHeader()
  })
}

const makeInvoices = () => {
  return http.axiosClient.post('/orders/makeInvoices', null, {
    headers: authHeader()
  })
}

export default {
  findAll,
  findById,
  create,
  update,
  remove,
  makeInvoices
}
