'use strict'

import http from './http-common'
import authHeader from './auth-header'
import useSWR, { mutate, useSWRConfig } from 'swr'

const findAll = (registryId) => {
  return http.axiosClient.get(
    registryId ? `/registries/${registryId}/invoices` : '/invoices',
    { headers: authHeader() }
  )
}

const findById = (id) => {
  return http.axiosClient.get(`/invoices/${id}`, {
    headers: authHeader()
  })
}

const create = (registryId, data) => {
  return http.axiosClient.post('/invoices', data, {
    headers: authHeader()
  })
}

const update = (id, data) => {
  return http.axiosClient.patch(`/invoices/${id}`, data, {
    headers: authHeader()
  })
}

const remove = (id) => {
  return http.axiosClient.delete(`/invoices/${id}`, {
    headers: authHeader()
  })
}

const downloadXmlInvoice = (id) => {
  return http.axiosClient.get(`/invoices/${id}/xml`, {
    responseType: 'blob',
    headers: authHeader()
  })
}

const downloadXmlInvoices = () => {
  return http.axiosClient.get('/invoices/fatture.zip', {
    responseType: 'blob'
  })
}

export default {
  findAll,
  findById,
  create,
  update,
  remove,
  downloadXmlInvoice,
  downloadXmlInvoices
}
