'use strict'

import React, { useEffect } from 'react'
// @material-ui/core components
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import MenuItem from '@mui/material/MenuItem'
import FormControlLabel from '@mui/material/FormControlLabel'
import CircularProgress from '@mui/material/CircularProgress'

// Material Dashboard 2 PRO React components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
// import MDTypography from 'components/MDTypography'
import MDSnackbar from 'components/MDSnackbar'

// zerouno
import eventService from 'services/event.service'
// import enumerationService from 'services/enumeration.service'
import { useForm, Controller } from 'react-hook-form'
import { useParams, Link, useNavigate } from 'react-router-dom'
import ZUTitleForm from 'components/ZeroUno/ZUTitleForm'
import ZUSelect from 'components/ZeroUno/ZUSelect'
import RegistryNav from './RegistryNav'
import { DateTime } from 'luxon'
import utils from 'utils/utils.js'
import { config } from 'variables/Config.js'

export default function EventForm() {
  const { idObj } = useParams()
  const { idRegistry } = useParams()
  const { idProject } = useParams()
  const [obj, setObj] = React.useState({
    startTime: '09:00',
    endDate: null,
    schedule: '1',
    notes: '',
    role: ''
  })
  const [showSnackbar, setShowSnackbar] = React.useState({
    sh: false,
    msg: '',
    color: 'success'
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    setError
  } = useForm()
  // const [valueD, setValueD] = React.useState(new Date())
  // const [valueE, setValueE] = React.useState(new Date())
  const navigate = useNavigate()
  const [file, setFile] = React.useState('')
  const [loading, setLoading] = React.useState(false)

  useEffect(() => {
    fetchObject()
  }, [])

  useEffect(() => {
    reset({ ...obj })
  }, [obj])

  const fetchObject = () => {
    if (idObj === 'new') {
      setObj({ ...obj, startDate: DateTime.now().setZone('Europe/Rome').toISODate() })
    } else {
      eventService.findById(idRegistry, idObj).then(
        response => {
          setObj(response.data)
        },
        error => {
          setShowSnackbar({
            sh: true,
            msg: error.response
              ? error.response.data.message
              : 'errore di ricezione dati',
            color: 'error'
          })
        }
      )
    }
  }

  const onSubmit = data => {
    data.file = file
    if (data.schedule != '1' && !data.endDate) {
      // errors.endDate = 'data fine non valida'
      setError('endDate', { type: 'focus', message: 'inserire la data fine' })
      setShowSnackbar({
        sh: true,
        msg: 'inserire la data fine',
        color: 'error'
      })

      // showSnackbar('data fine non valida', true)
      return
    }
    if (idObj === 'new') {
      addObj(data)
    } else {
      data.id = obj.id
      updateObj(data, obj.id)
    }
  }

  // Add obj
  const addObj = data => {
    // data.image = obj.image
    eventService.create(idRegistry, data).then(
      response => {
        setShowSnackbar({
          sh: true,
          msg: 'operazione effettuata con successo',
          color: 'success'
        })
        navigate(`/registries/${idRegistry}/events`)
      },
      error => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  const updateObj = (data, id) => {
    // data.image = obj.image
    eventService.update(idRegistry, id, data).then(
      response => {
        setShowSnackbar({
          sh: true,
          msg: 'operazione effettuata con successo',
          color: 'success'
        })
        navigate(`/registries/${idRegistry}/events`)
      },
      error => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  const uploadFile = files => {
    const newFile = files[0]
    if (newFile) {
      if (newFile.size <= 5 * 1024 * 1000) {
        setLoading(true)
        const formData = new FormData()
        formData.append('file', newFile)
        eventService.upload(formData, idObj).then(
          response => {
            setLoading(false)
            const filename = response.data
            setFile(filename)
          },
          error => {
            setLoading(false)
            setShowSnackbar({
              sh: true,
              msg: 'errore upload file ' + error.data.message,
              color: 'error'
            })
          }
        )
      } else {
        setShowSnackbar({ sh: true, msg: 'file troppo grande', color: 'error' })
      }
    }
  }

  const removeFile = () => {
    const formData = new FormData()
    formData.delete('file')
    setFile('')
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={10}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Card>
                <ZUTitleForm bgColor='info' title='Evento' icon='place' />

                <MDBox p={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                      <MDInput
                        fullWidth
                        type='text'
                        autoFocus
                        required
                        label='Titolo'
                        error={!!errors.title}
                        {...register('title', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} />
                    <Grid item xs={12} sm={12} md={2}>
                      <MDInput
                        fullWidth
                        type='date'
                        required
                        label='data evento'
                        error={!!errors.startDate}
                        {...register('startDate', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={6} sm={6} md={2}>

                      {obj.startTime && (
                        <ZUSelect
                          error={!!errors.startTime}
                          label='ora'
                          register={register}
                          name='startTime'
                          required
                          rules={{ required: true }}
                          defaultValue={obj.startTime}
                        >
                          {utils.time().map(item => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </ZUSelect>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                      <Controller
                        control={control}
                        name='allDay'
                        render={({ field: { value, onChange } }) => (
                          <FormControlLabel
                            control={
                              <Switch
                                checked={value || false}
                                onChange={onChange}
                              />
                            }
                            label='tutto il giorno'
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      {obj.schedule && (
                        <ZUSelect
                          error={!!errors.schedule}
                          label='Ripeti evento'
                          register={register}
                          name='schedule'
                          required
                          rules={{ required: true }}
                          defaultValue={obj.schedule}
                        >
                          {utils.scheduleEnum.map(item => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </ZUSelect>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      <MDInput
                        fullWidth
                        type='date'
                        label='fino a data'
                        error={!!errors.endDate}
                        {...register('endDate', { required: false })}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={2}>
                      {(obj.role || obj.role === '') && (
                        <ZUSelect
                          error={!!errors.role}
                          label='Visibile a'
                          register={register}
                          name='role'
                          rules={{ required: false }}
                          defaultValue={obj.role}
                        >
                          <MenuItem value=''>tutti</MenuItem>
                          <MenuItem value='user'>utenti</MenuItem>
                          <MenuItem value='admin'>admin</MenuItem>
                          <MenuItem value='tecnici'>tecnici</MenuItem>
                          <MenuItem value='amministrativi'>
                            amministrativi
                          </MenuItem>
                          <MenuItem value='commerciali'>commerciali</MenuItem>
                        </ZUSelect>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <MDInput
                        fullWidth
                        type='text'
                        multiline
                        rows={3}
                        required={false}
                        label='descrizione'
                        error={!!errors.description}
                        {...register('description', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <MDInput
                        fullWidth
                        type='text'
                        multiline
                        rows={3}
                        required={false}
                        label='note'
                        error={!!errors.notes}
                        {...register('notes', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                      <Stack spacing={2}>
                        {(file === null ||
                          file === undefined ||
                          file === '') && (
                            <label htmlFor='contained-button-file'>
                              <MDInput
                                accept='*'
                                id='contained-button-file'
                                type='file'
                                sx={{ display: 'none' }}
                                onChange={target =>
                                  uploadFile(target.target.files)}
                              />
                              <MDButton
                                variant='gradient'
                                color='info'
                                component='span'
                                style={{ marginTop: '30px' }}
                              >
                                Upload documento (max 5Mb)
                              </MDButton>
                            </label>
                          )}
                        {loading && (
                          <CircularProgress color='primary' sx={{ m: 2 }} />
                        )}
                        {file && (
                          <MDButton
                            color='primary'
                            href={`${config.SERVER_URL}/projects/${idProject}/attachments/download/${file}`}
                            target='_blank'
                            mb={2}
                          >
                            download
                          </MDButton>
                        )}
                        {file && (
                          <MDButton color='error' onClick={() => removeFile()}>
                            elimina file
                          </MDButton>
                        )}
                      </Stack>
                    </Grid>
                  </Grid>
                </MDBox>
                <MDBox p={2}>
                  <MDButton
                    variant='gradient'
                    color='secondary'
                    component={Link}
                    to={`/registries/${idRegistry}/events`}
                  >
                    Indietro
                  </MDButton>
                  <MDButton
                    type='submit'
                    variant='gradient'
                    color='success'
                    sx={{ ml: 1 }}
                  >
                    Salva
                  </MDButton>
                </MDBox>
              </Card>
            </form>
          </Grid>
          <Grid item xs={12} sm={12} md={2}>
            <RegistryNav id={idRegistry} />
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content={showSnackbar.msg}
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />
    </DashboardLayout>
  )
}
