'use strict'

import http from './http-common'
import authHeader from './auth-header'
import useSWR from 'swr'

const findAll = () => {
  return http.axiosClient.get('/registries', { headers: authHeader() })
}

const findById = (id) => {
  return http.axiosClient.get(`/registries/${id}`, { headers: authHeader() })
}

const getWithOrders = (id) => {
  return http.axiosClient.get(`/registries/${id}?include[]=order`, { headers: authHeader() })
}

const create = (data) => {
  return http.axiosClient.post('/registries', data, { headers: authHeader() })
}

const update = (id, data) => {
  return http.axiosClient.patch(`/registries/${id}`, data, { headers: authHeader() })
}

const remove = (id) => {
  return http.axiosClient.delete(`/registries/${id}`, { headers: authHeader() })
}

const findByName = (name) => {
  return http.axiosClient.get(`/registries?name=${name}`, { headers: authHeader() })
}

const findByUser = (userId) => {
  return http.axiosClient.get(`/registries?userId=${userId}`, { headers: authHeader() })
}

export default {
  findAll,
  findById,
  getWithOrders,
  create,
  update,
  remove,
  findByName,
  findByUser
}
