/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types'

// @mui material components
import Container from '@mui/material/Container'
import Link from '@mui/material/Link'
import Icon from '@mui/material/Icon'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

// Material Dashboard 2 PRO React base styles
import typography from 'assets/theme/base/typography'
// ZeroUno
import { config } from 'variables/Config.js'
import { Card, Grid } from '@mui/material'
import logoFesr from 'assets/images/zerounorabbit/logoFESR1420.png'

function Footer({ light }) {
  const { size } = typography

  return (
    <MDBox position='absolute' width='100%' bottom={0} py={4}>
      <Container>
        <Card
          sx={{ background: '#22222299' }}
        >
          <Grid container p={2}>
            <Grid item xs={12} md={4}>
              <MDBox
                width='100%'
                display='flex'
                justifyContent='center'
                alignItems='center'
                color={light ? 'white' : 'text'}
                fontSize={size.sm}
              >
                &copy; {new Date().getFullYear()}, made with
                <MDBox fontSize={size.md} color={light ? 'white' : 'dark'} mb={-0.5} mx={0.25}>
                  <Icon color='inherit' fontSize='inherit'>
                    favorite
                  </Icon>
                </MDBox>
                by
                <Link href='https://www.01rabbit.it/' target='_blank' rel='noreferrer'>
                  <MDTypography variant='button' fontWeight='medium' color={light ? 'white' : 'dark'}>
                    &nbsp;01Rabbit&nbsp;
                  </MDTypography>
                </Link>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={8}>
              <MDBox
                width='100%'
                display='flex'
                justifyContent='center'
                alignItems='center'
                color={light ? 'white' : 'text'}
                fontSize={size.sm}
              >
                <Link href='/Targa-FESR.pdf' target='_blank' rel='noreferrer'>
                  <MDTypography variant='button' color='white'>
                    Il presente sistema di ticketing è stato cofinanziato, assieme agli arredi ed alle postazioni informatiche della nuova sede di Comunica.Live sita a Potenza presso il Centro Commerciale Galassia, attraverso il bando ITI Potenza del Programma Operativo FESR Basilicata 2014-2020 Asse 3 - PROMUOVERE LA COMPETITIVITÀ DELLE PICCOLE E MEDIE IMPRESE del PO FESR 2014-2020
                  </MDTypography>
                </Link>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={4} />
            <Grid item xs={12} md={8}>
              <MDBox
                component='img'
                src={logoFesr}
                alt='logo'
                width='90%'
                height='90%'
                position='relative'
              />
            </Grid>
          </Grid>
        </Card>
      </Container>
    </MDBox>
  )
}

// Setting default props for the Footer
Footer.defaultProps = {
  light: false
}

// Typechecking props for the Footer
Footer.propTypes = {
  light: PropTypes.bool
}

export default Footer
