export default function ZULed({ status }) {
  const style = {
    led: {
      width: '10px',
      height: '10px',
      borderRadius: '50%',
      border: "1px solid rgba(0,0,0,.25)",
      margin: '5px',
      backgroundColor: status ? '#adff2f' : '#BBB',
      boxShadow: status ? "rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, #89FF00 0 2px 12px" : 'none',
    },
  }

  return <div style={style.led}></div>
}
