'use strict'

import http from './http-common'
import authHeader from './auth-header'
import useSWR from 'swr'

const findAll = () => {
  return http.axiosClient.get('/ddts', { headers: authHeader() })
}

const findById = (id) => {
  return http.axiosClient.get(`/ddts/${id}`, { headers: authHeader() })
}

const create = (data) => {
  return http.axiosClient.post('/ddts', data, { headers: authHeader() })
}

const update = (id, data) => {
  return http.axiosClient.patch(`/ddts/${id}`, data, {
    headers: authHeader()
  })
}

const remove = (id) => {
  return http.axiosClient.delete(`/ddts/${id}`, { headers: authHeader() })
}

const upload = (data) => {
  return http.axiosClient.post(`/ddts/upload`, data, { headers: authHeader() })
}

export default {
  findAll,
  findById,
  create,
  update,
  remove,
  upload
}
