'use strict'

import http from './http-common'
import authHeader from './auth-header'
import useSWR from 'swr'

const getAll = () => {
  const { data, error } = useSWR(['/enumerations', { headers: authHeader() }], http.fetcherAuth)
  return {
    data: data || [],
    isLoading: !error && !data,
    error: error
  }
}

// const get = (id) => {
//   const { data, error } = useSWR([`/enumerations/${id}`, { headers: authHeader() }], http.fetcherAuth)
//   return {
//     data: data || {},
//     isLoading: !error && !data,
//     error: error
//   }
// }

// const getAll = ({ attributes, include } = {}) => {
//   return http.axiosClient.get('/enumerations', { headers: authHeader() })
// }

const get = (id) => {
  return http.axiosClient.get(`/enumerations/${id}`, { headers: authHeader() })
}

const create = (data) => {
  return http.axiosClient.post('/enumerations', data, { headers: authHeader() })
}

const update = (id, data) => {
  return http.axiosClient.patch(`/enumerations/${id}`, data, { headers: authHeader() })
}

const remove = (id) => {
  return http.axiosClient.delete(`/enumerations/${id}`, { headers: authHeader() })
}

const findByType = (type) => {
  // return http.axiosClient.get(`/enumerations/type/${type}`, { headers: authHeader() })
  const { data, error } = useSWR([`/enumerations/type/${type}`, { headers: authHeader() }], http.fetcherAuth)
  return {
    data: data || [],
    isLoading: !error && !data,
    error: error
  }
}

/**
 * Restituisce la descrizione associata all'ID nel tipo enumerativo
 * @param {[{id: number, description: string, type: string, order: number}]} enumeration Array di record della tabella enumerations
 * @param {number} id ID del valore enumerativo richiesto
 * @returns Descrizione testuale del valore enumerativo
 */
const getEnumerationDescription = (enumeration, id) => {
  const description =
    enumeration &&
    enumeration.filter((record) => record.id === id)[0] &&
    enumeration.filter((record) => record.id === id)[0].description
  return description
}

export default {
  getAll,
  get,
  create,
  update,
  remove,
  findByType,
  getEnumerationDescription
}
