// prop-types is a library for typechecking of props
import Switch from '@mui/material/Switch'
import { Controller } from 'react-hook-form'
import FormControlLabel from '@mui/material/FormControlLabel'

export default function ZUSwitch({
  name,
  label,
  control
}) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <FormControlLabel
          control={<Switch checked={value || false} onChange={onChange} />}
          label={label}
        />
      )}
    />
  )
}
