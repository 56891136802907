'use strict'
import { useState, useEffect } from 'react'
// @mui material components
import Card from '@mui/material/Card'
import Icon from '@mui/material/Icon'
import Grid from '@mui/material/Grid'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import Switch from '@mui/material/Switch'
import Tooltip from '@mui/material/Tooltip'
// import Menu from '@mui/material/Menu'
// import MenuItem from '@mui/material/MenuItem'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDSnackbar from 'components/MDSnackbar'
import MDButton from 'components/MDButton'

// Material Dashboard 2 PRO React example components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'
import ZuDataTable from 'components/ZeroUno/ZuDataTable'

// zerouno
import todoService from 'services/todo.service'
import { useParams, Link } from 'react-router-dom'
import ZUTitleForm from 'components/ZeroUno/ZUTitleForm'
import utils from 'utils/utils.js'

export default function TodoTable() {
  const [objId, setObjId] = useState(0)
  const [showSnackbar, setShowSnackbar] = useState({
    sh: false,
    msg: '',
    color: 'success'
  })
  const [openConfirm, setOpenConfirm] = useState(false)
  const [objects, setObjects] = useState([])
  const [viewAll, setViewAll] = useState(false)
  const [filteredData, setFilteredData] = useState([])

  useEffect(() => {
    fetchObjects()
  }, [])

  const fetchObjects = () => {
    todoService.findAll().then(
      response => {
        setObjects(response.data)
        if (viewAll) {
          setFilteredData(response.data)
        } else {
          setFilteredData(response.data.filter(item => !item.done))
        }
      },
      error => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  // Delete
  const deleteObject = () => {
    setOpenConfirm(false)
    todoService.remove(objId).then(
      response => {
        setShowSnackbar({
          sh: true,
          msg: 'operazione effettuata con successo',
          color: 'success'
        })
        fetchObjects()
      },
      error => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  const toggleView = () => {
    if (viewAll) {
      // se ERA vedi tutti fai lo switch e...
      setFilteredData(objects.filter(item => !item.done))
    } else {
      setFilteredData(objects)
    }
    setViewAll(!viewAll)
  }

  const toggleSwitch = id => {
    todoService
      .findById(id)
      .then(response => response.data)
      .then(obj => {
        obj.done = !obj.done
        todoService.update(id, obj)
      })
      .then(response2 => {
        setShowSnackbar({
          sh: true,
          msg: 'operazione effettuata con successo',
          color: 'success'
        })
      })
      .then(() => {
        fetchObjects()
      })
      .catch(error =>
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      )
  }

  const columns = [
    {
      header: 'fatto',
      accessorFn: d => {
        return (
          <Switch
            key={'sw_' + d.id}
            checked={d.done}
            onChange={event => toggleSwitch(d.id)}
            value='1'
          />
        )
      }
    },
    {
      header: 'da fare',
      accessorFn: d => {
        return (
          <Tooltip
            key={'ttip_' + d.id}
            title={d.description}
            arrow
            followCursor
          >
            <MDTypography variant='inherit'>{d.title}</MDTypography>
          </Tooltip>
        )
      }
    },
    {
      header: 'data',
      accessorFn: d => {
        return utils.formatDate(d.createdAt, 'dd/MM/yyyy')
      }
    },
    {
      header: 'gruppo',
      accessorFn: d => (d.role === '' ? 'tutti' : d.role)
    },
    {
      header: 'assegnato a',
      accessorFn: d =>
        d.toUser ? d.toUser.name + ' ' + d.toUser.surname : 'nessuno'
    },
    {
      header: 'creato da ',
      accessorFn: d => d.fromUser.name + ' ' + d.fromUser.surname
    },
    {
      header: 'azioni',
      enableSorting: false,
      enableColumnFilter: false,
      width: 100,
      accessorFn: row => (
        <Grid container spacing={1}>
          <Grid item>
            <MDButton
              color='info'
              size='small'
              iconOnly
              component={Link}
              to={`/todos/${row.id}`}
            >
              <Icon>edit</Icon>
            </MDButton>
          </Grid>
          <Grid item>
            <MDButton
              iconOnly
              color='error'
              size='small'
              onClick={() => {
                setObjId(row.id)
                setOpenConfirm(true)
              }}
            >
              <Icon>delete</Icon>
            </MDButton>
          </Grid>
        </Grid>
      )
    }
  ]

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <Card>
              <ZUTitleForm bgColor='info' title='Todo' icon='place' />
              <MDBox p={3} lineHeight={1}>
                <MDButton color='info' component={Link} to='/todos/new' m={1}>
                  nuovo
                </MDButton>
                <MDButton
                  color='success'
                  onClick={() => toggleView()}
                  m={1}
                  sx={{ ml: 1 }}
                >
                  {viewAll ? 'vedi solo da fare' : 'vedi tutti'}
                </MDButton>
              </MDBox>
              <ZuDataTable table={{ columns: columns, rows: filteredData }} />
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <Dialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          Confermi eliminazione?
        </DialogTitle>
        <DialogActions>
          <MDButton onClick={() => setOpenConfirm(false)}>Annulla</MDButton>
          <MDButton onClick={deleteObject} autoFocus>
            Conferma
          </MDButton>
        </DialogActions>
      </Dialog>
      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content={showSnackbar.msg}
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />
    </DashboardLayout>
  )
}
