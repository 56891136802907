'use strict'

import React, { useEffect, useState } from 'react'
// @material-ui/core components
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'

// Material Dashboard 2 PRO React components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDSnackbar from 'components/MDSnackbar'

// zerouno
import deviceService from 'services/device.service'
import deviceModelService from 'services/device-model.service'
import registryService from 'services/registry.service'
import { useForm } from 'react-hook-form'
import { useParams, useLocation, Link, useNavigate } from 'react-router-dom'
import ZUTitleForm from 'components/ZeroUno/ZUTitleForm'
import { DateTime } from 'luxon'
import { Checkbox } from '@mui/material'
import MDTypography from 'components/MDTypography'
import ZUAutoComplete from 'components/ZeroUno/ZUAutoComplete'
import ZUSwitch from 'components/ZeroUno/ZUSwitch'

export default function DeviceForm() {
  const { idObj } = useParams()
  const [obj, setObj] = useState({
    invoiceDate: DateTime.local().toISODate(),
    registry: null,
    registryId: null,
    model: null,
    modelId: null,
    active: true
  })
  const [registries, setRegistries] = useState([])
  const location = useLocation()

  const clone = new URLSearchParams(useLocation().search).has('clone')

  const [showSnackbar, setShowSnackbar] = useState({
    sh: false,
    msg: '',
    color: 'success'
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue
  } = useForm()
  // const [valueD, setValueD] = React.useState(new Date())
  // const [valueE, setValueE] = React.useState(new Date())
  const navigate = useNavigate()
  const [file, setFile] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [models, setModels] = React.useState([])

  useEffect(() => {
    fetchObject()
    fetchModels()
    fetchRegistries()
  }, [])

  useEffect(() => {
    reset({ ...obj })
  }, [obj])

  useEffect(() => {
    fetchObject()
  }, [location])

  const fetchObject = () => {
    if (idObj !== 'new') {
      deviceService.findById(idObj).then(
        response => {
          let device = response.data
          if (clone) {
            device = { ...device, id: null, serial: '', macAddress: '' }
          }
          setObj(device)
        },
        error => {
          setShowSnackbar({
            sh: true,
            msg: error.response
              ? error.response.data.message
              : 'errore di ricezione dati',
            color: 'error'
          })
        }
      )
    }
  }

  const fetchModels = () => {
    deviceModelService.findAll().then(
      response => {
        setModels(response.data)
      },
      error => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  const fetchRegistries = () => {
    registryService.findAll().then(
      response => {
        setRegistries(response.data)
      },
      error =>
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
    )
  }

  const onSubmit = (data, e) => {
    // data.file = file
    data.modelId = data.model.id
    data.registryId = data.registry.id
    if (idObj === 'new' || clone) {
      addObj(data, e)
    } else {
      data.id = obj.id
      updateObj(data, obj.id)
    }
  }

  // Add obj
  const addObj = (data, e) => {
    // data.image = obj.image
    deviceService.create(data).then(
      response => {
        setShowSnackbar({
          sh: true,
          msg: 'operazione effettuata con successo',
          color: 'success'
        })
        if (e.nativeEvent.submitter.id === 'saveAndClone') {
          navigate('/devices/' + idObj + '?clone')
        } else {
          navigate('/devices')
        }
      },
      error => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  const updateObj = (data, id) => {
    // data.image = obj.image
    deviceService.update(id, data).then(
      response => {
        setShowSnackbar({
          sh: true,
          msg: 'operazione effettuata con successo',
          color: 'success'
        })
        navigate('/devices')
      },
      error => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Card>
                <ZUTitleForm bgColor='info' title='Apparato' icon='place' />

                <MDBox p={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                      <ZUAutoComplete
                        control={control}
                        label='modello'
                        name='model'
                        options={models}
                        defaultValue={obj.model}
                        error={!!errors.model}
                        required
                        rules={{ required: true }}
                        getOptionLabel={item => item.name}
                        getOptionKey={item => item.id}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} />
                    <Grid item xs={12} sm={12} md={3}>
                      <MDInput
                        fullWidth
                        type='text'
                        autoFocus
                        required
                        label='Serial number'
                        error={!!errors.serial}
                        {...register('serial', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      <MDInput
                        fullWidth
                        type='text'
                        autoFocus
                        label='Mac address'
                        error={!!errors.macAddress}
                        {...register('macAddress', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      {obj && (
                        <ZUSwitch name='active' label='attivo' control={control} />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} />
                    <Grid item xs={12} sm={12} md={3}>
                      <MDInput
                        fullWidth
                        type='text'
                        autoFocus
                        required
                        label='Numero fattura'
                        error={!!errors.invoiceNumber}
                        {...register('invoiceNumber', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      <MDInput
                        fullWidth
                        type='date'
                        required
                        label='data fattura'
                        error={!!errors.invoiceDate}
                        {...register('invoiceDate', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      <ZUAutoComplete
                        control={control}
                        label='fornitore'
                        name='registry'
                        options={registries}
                        defaultValue={obj.registry || null}
                        error={!!errors.registry}
                        required
                        rules={{ required: true }}
                        getOptionLabel={item => item.businessName ?? ''}
                        getOptionKey={item => item.id}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <MDInput
                        fullWidth
                        type='text'
                        multiline
                        rows={3}
                        required={false}
                        label='note'
                        error={!!errors.notes}
                        {...register('notes', { required: false })}
                      />
                    </Grid>
                  </Grid>
                </MDBox>
                <MDBox p={2}>
                  <MDButton
                    variant='gradient'
                    color='secondary'
                    component={Link}
                    to='/devices'
                  >
                    Indietro
                  </MDButton>
                  <MDButton
                    type='submit'
                    variant='gradient'
                    color='success'
                    sx={{ ml: 1 }}
                    id='save'
                  >
                    Salva
                  </MDButton>
                  <MDButton
                    type='submit'
                    variant='gradient'
                    color='success'
                    sx={{ ml: 1 }}
                    id='saveAndClone'
                  >
                    Salva e Clona
                  </MDButton>
                </MDBox>
              </Card>
            </form>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content={showSnackbar.msg}
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />
    </DashboardLayout>
  )
}
