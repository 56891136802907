'use strict'

import http from './http-common'
import authHeader from './auth-header'
import useSWR, { mutate, useSWRConfig } from 'swr'

const getAll = (registryId) => {
  const { data, error } = useSWR([`/registries/${registryId}/addresses`, { headers: authHeader() }], http.fetcherAuth)
  return {
    data: data || [],
    isLoading: !error && !data,
    error: error
  }
}

const getByRegistry = (registryId) => {
  return http.axiosClient.get(`/registries/${registryId}/addresses`, {
    headers: authHeader()
  })
}

// const get = (customerId, id) => {
//   if (id === 'new') {
//     return {
//       data: {},
//       isLoading: false
//     }
//   } else {
//     const { data, error } = useSWR([`/registries/${customerId}/addresses/${id}`, { headers: authHeader() }], http.fetcherAuth)
//     return {
//       data: data || {},
//       isLoading: !error && !data,
//       error: error
//     }
//   }
// }

// const getAll = (customerId) => {
//   return http.axiosClient.get(`/registries/${customerId}/addresses`, {
//     headers: authHeader()
//   })
// }

const get = (registryId, id) => {
  return http.axiosClient.get(`/registries/${registryId}/addresses/${id}`, {
    headers: authHeader()
  })
}

const create = (registryId, data) => {
  return http.axiosClient.post(`/registries/${registryId}/addresses`, data, {
    headers: authHeader()
  })
}

const update = (registryId, id, data) => {
  return http.axiosClient.put(`/registries/${registryId}/addresses/${id}`, data, {
    headers: authHeader()
  })
}

const remove = (registryId, id) => {
  return http.axiosClient.delete(`/registries/${registryId}/addresses/${id}`, {
    headers: authHeader()
  })
}
const refresh = (registryId) => {
  mutate([`/registries/${registryId}/addresses`, {
    headers: authHeader()
  }])
}
export default {
  getAll,
  getByRegistry,
  get,
  create,
  update,
  remove,
  refresh
}
