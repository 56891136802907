'use strict'

import http from './http-common'
import authHeader from './auth-header'
import useSWR, { mutate, useSWRConfig } from 'swr'

const findAll = () => {
  return http.axiosClient.get('/todos', {
    headers: authHeader()
  })
}

const findOpen = () => {
  return http.axiosClient.get('/todos/open', {
    headers: authHeader()
  })
}

const findById = id => {
  return http.axiosClient.get(`/todos/${id}`, {
    headers: authHeader()
  })
}

const create = data => {
  return http.axiosClient.post('/todos', data, {
    headers: authHeader()
  })
}

const update = (id, data) => {
  return http.axiosClient.patch(`/todos/${id}`, data, {
    headers: authHeader()
  })
}

const remove = id => {
  return http.axiosClient.delete(`/todos/${id}`, {
    headers: authHeader()
  })
}
const refresh = () => {
  const url = '/todos'
  mutate([
    url,
    {
      headers: authHeader()
    }
  ])
}

const refreshOpen = () => {
  const url = '/todos/open'
  mutate([
    url,
    {
      headers: authHeader()
    }
  ])
}

export default {
  findAll,
  findOpen,
  findById,
  create,
  update,
  remove,
  refresh,
  refreshOpen
}
