'use strict'

import http from './http-common'
import authHeader from './auth-header'
import useSWR from 'swr'

const findById = (registryId, id) => {
  return http.axiosClient.get(`/registries/${registryId}/devicecustomers/${id}`, { headers: authHeader() })
}

const findByRegistry = (registryId, addressId = null) => {
  return http.axiosClient.get(`/registries/${registryId}/devicecustomers${addressId ? `?idAddress=${addressId}` : ''}`, { headers: authHeader() })
}

const create = (registryId, data) => {
  return http.axiosClient.post(`/registries/${registryId}/devicecustomers`, data, { headers: authHeader() })
}

const update = (registryId, id, data) => {
  return http.axiosClient.patch(`/registries/${registryId}/devicecustomers/${id}`, data, {
    headers: authHeader()
  })
}

const upload = (registryId, data) => {
  return http.axiosClient.post(`/registries/${registryId}/devicecustomers/upload`, data, { headers: authHeader() })
}

const remove = (registryId, id) => {
  return http.axiosClient.delete(`/registries/${registryId}/devicecustomers/${id}`, { headers: authHeader() })
}

export default {
  findById,
  findByRegistry,
  create,
  update,
  upload,
  remove
}
