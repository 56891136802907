'use strict'

import http from './http-common'
import authHeader from './auth-header'
import useSWR, { mutate, useSWRConfig } from 'swr'

const findAll = (registryId) => {
  const url = registryId ? `/troubletickets?registryId=${registryId}` : '/troubletickets'
  return http.axiosClient.get(url, { headers: authHeader() })
}

const findOpen = () => {
  const url = '/troubletickets/open'
  const { data, error } = useSWR([url, { headers: authHeader() }], http.fetcherAuth)
  return {
    data: data || [],
    isLoading: !error && !data,
    error: error
  }
}

const findPublic = () => {
  const url = '/troubletickets/public'
  return http.axiosClient.get(url)
}

const findById = (id) => {
  return http.axiosClient.get(`/troubletickets/${id}`, {
    headers: authHeader()
  })
}

const create = (data, registryId, troubleTicketsId) => {
  const url = `/troubletickets?registryId=${registryId}`
  return http.axiosClient.post(`/troubletickets?registryId=${registryId}`, data, {
    headers: authHeader()
  })
}

const update = (id, data) => {
  return http.axiosClient.patch(`/troubletickets/${id}`, data, {
    headers: authHeader()
  })
}

const remove = (id) => {
  return http.axiosClient.delete(`/troubletickets/${id}`, {
    headers: authHeader()
  })
}
const refresh = (registryId) => {
  const url = registryId ? `/troubletickets?registryId=${registryId}` : '/troubletickets'
  mutate([url, {
    headers: authHeader()
  }])
  mutate(['/troubletickets/open', {
    headers: authHeader()
  }])
}

const upload = (data) => {
  return http.axiosClient.post('/troubletickets/upload', data, { headers: authHeader() })
}

const download = (file) => {
  return http.axiosClient.get(`/troubletickets/download/${file}`, { headers: authHeader() })
}

const markRead = (troubleTicketsId) => {
  const url = `/ttusers/read/${troubleTicketsId}`
  return http.axiosClient.post(url, {}, {
    headers: authHeader()
  })
}

export default {
  findAll,
  findOpen,
  findPublic,
  findById,
  create,
  update,
  remove,
  refresh,
  upload,
  download,
  markRead
}
