import React, { useEffect, useContext } from 'react'
// @material-ui/core components
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import Autocomplete from '@mui/material/Autocomplete'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import { Radio, FormLabel, RadioGroup, CircularProgress } from '@mui/material'

import Switch from '@mui/material/Switch'
// import Select from '@mui/material/Select'
import Icon from '@mui/material/Icon'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
// import InputLabel from '@mui/material/InputLabel'
// import MenuItem from '@mui/material/MenuItem'
// import FormControl from '@mui/material/FormControl'
// import FormHelperText from '@mui/material/FormHelperText'
import FormControlLabel from '@mui/material/FormControlLabel'

// Material Dashboard 2 PRO React components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
// import MDEditor from 'components/MDEditor'
import MDInput from 'components/MDInput'
import MDDatePicker from 'components/MDDatePicker'
import MDTypography from 'components/MDTypography'
import MDSnackbar from 'components/MDSnackbar'
import RabbitContext from 'context/rabbit.js'

import enumerationService from 'services/enumeration.service'
import tagService from 'services/tag.service'

// zerouno
import registryService from 'services/registry.service'
import userService from 'services/user.service'
import { useForm, Controller } from 'react-hook-form'
import { useParams, Link, useNavigate } from 'react-router-dom'
import ZUTitleForm from 'components/ZeroUno/ZUTitleForm'
import RegistryNav from './RegistryNav'
import ZUSelect from 'components/ZeroUno/ZUSelect'

import utils from 'utils/utils'

export default function RegistryForm () {
  const { idObj } = useParams()
  const [obj, setObj] = React.useState({
    address: {
      main: true
    },
    contact: {}
  })
  const [showSnackbar, setShowSnackbar] = React.useState({
    sh: false,
    msg: '',
    color: 'success'
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    watch
  } = useForm()
  const navigate = useNavigate()
  const {
    data: enums,
    isLoading: loadEnum,
    error: errEnums
  } = enumerationService.findByType('ticketPermission')
  const [contactMode, setContactMode] = React.useState('existing')
  const [users, setUsers] = React.useState([])
  const [fetchedTags, setFetchedTags] = React.useState([])
  const [tags, setTags] = React.useState([])
  const [objTags, setObjTags] = React.useState([])
  const [contactTags, setContactTags] = React.useState([])
  const [isLoadingSubmit, setIsLoadingSubmit] = React.useState(false)
  const isVisibleEmailMessage = [11, 12].includes(
    watch('contact.ticketPermissionId', false)
  )

  useEffect(() => {
    fetchObject()
    fetchUsers()
    fetchTags()
  }, [])

  useEffect(() => {
    reset({ ...obj })
  }, [obj])

  const fetchObject = () => {
    if (idObj === 'new') {
      setObj({
        address: {
          main: true
        },
        contact: { ticketPermissionId: '' },
        payment: 'rid',
        tags: []
      })
    } else {
      registryService.findById(idObj).then(
        response => {
          setObj(response.data)
          if (response.data.tags) {
            setObjTags(response.data.tags)
          }
        },
        error => {
          setShowSnackbar({
            sh: true,
            msg: error.response
              ? error.response.data.message
              : 'errore di ricezione dati',
            color: 'error'
          })
        }
      )
    }
  }

  const fetchUsers = () => {
    userService.findAll().then(
      response => {
        setUsers(response.data)
      },
      error => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  const fetchTags = () => {
    tagService.getAllTags().then(
      response => {
        setFetchedTags(response.data)
        setTags(response.data)
      },
      error => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  const onClickContactRadio = mode => {
    setObj({
      ...obj,
      contact: {
        user: null,
        ticketPermissionId: '',
        notes: ''
      }
    })
    setContactMode(mode)
  }

  const resetTagList = () => {
    setTags([...fetchedTags])
  }

  const handleClose = (event, reason) => {
    if (reason == 'blur') {
      resetTagList()
    }
  }

  const mergeLists = (firstArr, secondArr) => {
    const result = firstArr
    secondArr.forEach(el => {
      if (!result.map(x => x.name).includes(el.name)) {
        result.push(el)
      }
    })
    return result
  }

  const updateTagList = text => {
    if (fetchedTags.filter(x => x.name == text).length == 0) {
      setTags([
        ...fetchedTags,
        {
          name: text
        }
      ])
    } else {
      setTags([...fetchedTags])
    }
  }

  const onSubmit = data => {
    data.tags = objTags
    if (idObj === 'new') {
      data.contact.tags = contactTags
      addObj(data)
    } else {
      data.id = obj.id
      updateObj(data, obj.id)
    }
  }

  // Add obj
  const addObj = data => {
    setIsLoadingSubmit(true)
    // data.image = obj.image
    registryService.create(data).then(
      response => {
        setShowSnackbar({
          sh: true,
          msg: 'operazione effettuata con successo',
          color: 'success'
        })
        // const cust = response.data
        setIsLoadingSubmit(false)
        navigate('/registries')
      },
      error => {
        setIsLoadingSubmit(false)
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  const updateObj = (data, id) => {
    // data.image = obj.image
    setIsLoadingSubmit(true)
    registryService.update(id, data).then(
      response => {
        setShowSnackbar({
          sh: true,
          msg: 'operazione effettuata con successo',
          color: 'success'
        })
        setIsLoadingSubmit(false)
        navigate('/registries')
      },
      error => {
        setIsLoadingSubmit(false)
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={10}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Card>
                <ZUTitleForm bgColor='info' title='Cliente' icon='person' />
                <MDBox p={2}>
                  <Accordion defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel01-content'
                      id='panel01-header'
                    >
                      <MDTypography>Dati Anagrafica</MDTypography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={4}>
                          <MDInput
                            fullWidth
                            type='text'
                            autoFocus
                            required
                            label='ragione sociale'
                            error={!!errors.businessName}
                            {...register('businessName', { required: true })}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4} md={2}>
                          {false && (
                            <Controller
                              control={control}
                              name='physicalPerson'
                              render={({ field: { value, onChange } }) => (
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={value || false}
                                      onChange={onChange}
                                    />
                                  }
                                  label='persona fisica'
                                />
                              )}
                            />
                          )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                          {false && (
                            <MDInput
                              fullWidth
                              type='text'
                              label='nome'
                              error={!!errors.name}
                              {...register('name', { required: false })}
                            />
                          )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                          {false && (
                            <MDInput
                              fullWidth
                              type='text'
                              label='cognome'
                              error={!!errors.surname}
                              {...register('surname', { required: false })}
                            />
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <MDInput
                            fullWidth
                            type='text'
                            required={false}
                            label='partita iva'
                            error={!!errors.vat}
                            {...register('vat', { required: false })}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <MDInput
                            fullWidth
                            type='text'
                            required={false}
                            label='codice fiscale'
                            error={!!errors.fiscalCode}
                            {...register('fiscalCode', { required: false })}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <MDInput
                            fullWidth
                            type='email'
                            required={false}
                            label='pec'
                            error={!!errors.pec}
                            {...register('pec', {
                              pattern: {
                                value:
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: 'pec non valida'
                              }
                            })}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <MDInput
                            fullWidth
                            type='text'
                            label='codice destinatario'
                            required={false}
                            error={!!errors.codeInvoice}
                            {...register('codeInvoice', { required: false })}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <MDInput
                            fullWidth
                            type='text'
                            label='iban'
                            required={false}
                            error={!!errors.iban}
                            {...register('iban', { required: false })}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          {obj.payment && (
                            <ZUSelect
                              error={!!errors.payment}
                              label='Modalità pagamento'
                              register={register}
                              name='payment'
                              required
                              rules={{ required: true }}
                              defaultValue={obj.payment}
                            >
                              <MenuItem value='rid'>rid ssd</MenuItem>
                              <MenuItem value='dir'>rimessa diretta</MenuItem>
                            </ZUSelect>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <MDInput
                            fullWidth
                            type='email'
                            label='email'
                            error={!!errors.email}
                            {...register('email', {
                              pattern: {
                                value:
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: 'mail non valida'
                              }
                            })}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <MDInput
                            fullWidth
                            type='text'
                            label='url'
                            required={false}
                            error={!!errors.url}
                            {...register('url', { required: false })}
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                          <MDInput
                            fullWidth
                            type='text'
                            multiline
                            rows={3}
                            required={false}
                            label='note'
                            error={!!errors.notes}
                            {...register('notes', { required: false })}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <Controller
                            name='tags'
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <Autocomplete
                                multiple
                                value={objTags || []}
                                options={tags.map(option => option)}
                                getOptionLabel={item =>
                                  item.name ? item.name : ''
                                }
                                onChange={(_, item) => {
                                  const _temp = mergeLists(tags, item)
                                  setTags(_temp)
                                  setObjTags(item)
                                  onChange(item || [])
                                }}
                                onClose={handleClose}
                                renderInput={params => (
                                  <MDInput
                                    {...params}
                                    fullWidth
                                    label='tag'
                                    onChange={e =>
                                      updateTagList(e.target.value)
                                    }
                                    error={!!errors.user}
                                  />
                                )}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                      {idObj === 'new' && (
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12} md={12} marginTop='2rem'>
                            <MDTypography>Indirizzo principale</MDTypography>
                          </Grid>
                          <Grid item xs={12} sm={12} md={10}>
                            <MDInput
                              fullWidth
                              type='text'
                              required
                              label='indirizzo'
                              // error={!!(errors('address.address'))}
                              {...register('address.address', {
                                required: true
                              })}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={2}>
                            <MDInput
                              fullWidth
                              type='text'
                              required={false}
                              label='civico'
                              // error={!!(errors('address.civicNumber'))}
                              {...register('address.civicNumber', {
                                required: false
                              })}
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={4}>
                            <MDInput
                              fullWidth
                              type='text'
                              required
                              label='città'
                              // error={!!(errors('address.city'))}
                              {...register('address.city', { required: true })}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={2}>
                            <MDInput
                              fullWidth
                              type='text'
                              required
                              label='prov'
                              inputProps={{
                                maxLength: 2
                              }}
                              error={!!errors.prov}
                              {...register('address.prov', { required: true })}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={2}>
                            <MDInput
                              fullWidth
                              type='text'
                              required
                              label='cap'
                              inputProps={{
                                maxLength: 5
                              }}
                              // error={!!(errors('address.cap'))}
                              {...register('address.cap', { required: true })}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <MDInput
                              fullWidth
                              type='text'
                              required={false}
                              label='telefono'
                              // error={!!(errors('address.phone'))}
                              {...register('address.phone', {
                                required: false
                              })}
                            />
                          </Grid>
                          {/* <Grid item xs={12} sm={4} md={3}>
                            <Controller
                              control={control}
                              name='main'
                              render={({ field: { value, onChange } }) => (
                                <FormControlLabel
                                  control={<Switch checked={value || false} onChange={onChange} />}
                                  label='indirizzo principale'
                                />
                              )}
                            />
                          </Grid> */}
                        </Grid>
                      )}
                    </AccordionDetails>
                  </Accordion>
                  {idObj === 'new' && (
                    <Accordion defaultExpanded>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel02-content'
                        id='panel02-header'
                      >
                        <MDTypography>Dati Contatto</MDTypography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <RadioGroup
                                aria-labelledby='demo-radio-buttons-group-label'
                                defaultValue='existing'
                                name='radio-buttons-group'
                              >
                                <FormControlLabel
                                  value='existing'
                                  control={
                                    <Radio
                                      onClick={() =>
                                        onClickContactRadio('existing')
                                      }
                                    />
                                  }
                                  label='Seleziona un contatto'
                                />
                                {contactMode == 'existing' && (
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={4}>
                                      <Controller
                                        name='user'
                                        control={control}
                                        render={({
                                          field: { value, onChange }
                                        }) => (
                                          <Autocomplete
                                            options={users}
                                            value={obj.contact.user}
                                            onChange={(_, item) => {
                                              setObj({
                                                ...obj,
                                                contact: {
                                                  user: item,
                                                  userId: item ? item.id : 'n'
                                                }
                                              })
                                              onChange(item ? item.id : null)
                                            }}
                                            getOptionLabel={item =>
                                              item.surname
                                            }
                                            isOptionEqualToValue={(
                                              item,
                                              val
                                            ) => {
                                              return item.id == val.id
                                            }}
                                            renderInput={params => (
                                              <MDInput
                                                {...params}
                                                fullWidth
                                                label='Cliente'
                                                error={!!errors.contact}
                                              />
                                            )}
                                          />
                                        )}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4}>
                                      <MDInput
                                        fullWidth
                                        type='text'
                                        required={false}
                                        label='ruolo'
                                        error={!!errors.contact?.notes}
                                        {...register('contact.notes', {
                                          required: false
                                        })}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4}>
                                      <FormControl
                                        fullWidth
                                        required
                                        error={!!errors.ticketPermissionId}
                                      >
                                        <InputLabel>
                                          Permessi tickets
                                        </InputLabel>
                                        <Select
                                          defaultValue=''
                                          label='Permessi tickets'
                                          {...register(
                                            'contact.ticketPermissionId',
                                            {
                                              required: true
                                            }
                                          )}
                                        >
                                          {enums &&
                                            enums.map(item => (
                                              <MenuItem
                                                key={item.id}
                                                value={item.id}
                                              >
                                                {item.description}
                                              </MenuItem>
                                            ))}
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                      <Controller
                                        name='contactTags'
                                        control={control}
                                        render={({
                                          field: { value, onChange }
                                        }) => (
                                          <Autocomplete
                                            multiple
                                            value={contactTags || []}
                                            options={tags.map(option => option)}
                                            getOptionLabel={item =>
                                              item.name ? item.name : ''
                                            }
                                            onChange={(_, item) => {
                                              const _temp = mergeLists(
                                                tags,
                                                item
                                              )
                                              setTags(_temp)
                                              setContactTags(item)
                                              onChange(item || [])
                                            }}
                                            onClose={handleClose}
                                            renderInput={params => (
                                              <MDInput
                                                {...params}
                                                fullWidth
                                                label='tag'
                                                onChange={e =>
                                                  updateTagList(e.target.value)
                                                }
                                                error={!!errors.user}
                                              />
                                            )}
                                          />
                                        )}
                                      />
                                    </Grid>
                                  </Grid>
                                )}
                                <FormControlLabel
                                  style={{ marginTop: '1rem' }}
                                  value='new'
                                  control={
                                    <Radio
                                      onClick={() => onClickContactRadio('new')}
                                    />
                                  }
                                  label='Inserisci i dati di un nuovo contatto'
                                />
                                {contactMode == 'new' && (
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={4}>
                                      <MDInput
                                        fullWidth
                                        type='text'
                                        required
                                        label='nome'
                                        error={!!errors.contact?.name}
                                        {...register('contact.user.name', {
                                          required: true
                                        })}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4}>
                                      <MDInput
                                        fullWidth
                                        type='text'
                                        required
                                        label='cognome'
                                        // error={!!(errors('contact.surname'))}
                                        {...register('contact.user.surname', {
                                          required: true
                                        })}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} />
                                    <Grid item xs={12} sm={6} md={4}>
                                      <MDInput
                                        fullWidth
                                        type='email'
                                        required
                                        label='email'
                                        error={!!errors.contact?.user?.email}
                                        {...register('contact.user.email', {
                                          pattern: {
                                            value:
                                              /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: 'mail non valida'
                                          },
                                          required: true
                                        })}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                      <MDInput
                                        fullWidth
                                        type='text'
                                        required={false}
                                        label='telefono'
                                        // error={!!(errors('contact.phone'))}
                                        {...register('contact.user.phone', {
                                          required: false
                                        })}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                      <MDInput
                                        fullWidth
                                        type='text'
                                        required={false}
                                        label='cellulare'
                                        // error={!!(errors('contact.mobile'))}
                                        {...register('contact.user.mobile', {
                                          required: false
                                        })}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4}>
                                      <MDInput
                                        fullWidth
                                        type='text'
                                        required={false}
                                        label='ruolo'
                                        error={!!errors.contact?.notes}
                                        {...register('contact.notes', {
                                          required: false
                                        })}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4}>
                                      <FormControl
                                        fullWidth
                                        required
                                        error={!!errors.ticketPermissionId}
                                      >
                                        <InputLabel>
                                          Permessi tickets
                                        </InputLabel>
                                        <Select
                                          label='Permessi tickets'
                                          defaultValue=''
                                          {...register(
                                            'contact.ticketPermissionId',
                                            {
                                              required: true
                                            }
                                          )}
                                        >
                                          {enums &&
                                            enums.map(item => (
                                              <MenuItem
                                                key={item.id}
                                                value={item.id}
                                              >
                                                {item.description}
                                              </MenuItem>
                                            ))}
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                    {isVisibleEmailMessage && (
                                      <Grid item xs={12} sm={12} md={12}>
                                        <MDTypography variant='subtitle2'>
                                          N.B.: verrà inviata una mail
                                          all'indirizzo indicato per la
                                          registrazione della password
                                        </MDTypography>
                                      </Grid>
                                    )}
                                    <Grid item xs={12} sm={12} md={6}>
                                      <Controller
                                        name='contactTags'
                                        control={control}
                                        render={({
                                          field: { value, onChange }
                                        }) => (
                                          <Autocomplete
                                            multiple
                                            value={contactTags || []}
                                            options={tags.map(option => option)}
                                            getOptionLabel={item =>
                                              item.name ? item.name : ''
                                            }
                                            onChange={(_, item) => {
                                              const _temp = mergeLists(
                                                tags,
                                                item
                                              )
                                              setTags(_temp)
                                              setContactTags(item)
                                              onChange(item || [])
                                            }}
                                            onClose={handleClose}
                                            renderInput={params => (
                                              <MDInput
                                                {...params}
                                                fullWidth
                                                label='tag'
                                                onChange={e =>
                                                  updateTagList(e.target.value)
                                                }
                                                error={!!errors.user}
                                              />
                                            )}
                                          />
                                        )}
                                      />
                                    </Grid>
                                  </Grid>
                                )}
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  )}
                </MDBox>
                <MDBox p={2}>
                  <MDButton
                    variant='gradient'
                    color='secondary'
                    component={Link}
                    to='/registries/'
                  >
                    Indietro
                  </MDButton>
                  <MDButton
                    type='submit'
                    variant='gradient'
                    color='success'
                    disabled={isLoadingSubmit}
                    sx={{ ml: 1 }}
                  >
                    Salva
                    {isLoadingSubmit && (
                      <CircularProgress
                        color='white'
                        style={{
                          width: '20px',
                          height: '20px',
                          marginLeft: '10px'
                        }}
                      />
                    )}
                  </MDButton>
                </MDBox>
              </Card>
            </form>
          </Grid>
          <Grid item xs={12} sm={12} md={2}>
            <RegistryNav id={idObj} />
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content={showSnackbar.msg}
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />
    </DashboardLayout>
  )
}
