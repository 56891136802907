'use strict'

import http from './http-common'
import authHeader from './auth-header'
import useSWR, { mutate, useSWRConfig } from 'swr'

const getAddresses = (data) => {
  return http.axiosClient.get('/newsletter/addresses', { headers: authHeader() })
}

const getAll = () => {
  return http.axiosClient.get(`/newsletter`, {
    headers: authHeader()
  })
}

const get = (id) => {
  return http.axiosClient.get(`/newsletter/${id}`, {
    headers: authHeader()
  })
}

const create = (data) => {
  return http.axiosClient.post('/newsletter', data, {
    headers: authHeader()
  })
}

const update = (id, data) => {
  return http.axiosClient.patch(`/newsletter/${id}`, data, {
    headers: authHeader()
  })
}

const remove = (id) => {
  return http.axiosClient.delete(`/newsletter/${id}`, {
    headers: authHeader()
  })
}

const send = (data) => {
  return http.axiosClient.post('/newsletter/send', data, { headers: authHeader() })
}

export default {
  getAddresses,
  getAll,
  get,
  create,
  update,
  remove,
  send,
}
