'use strict'

import React, { useEffect } from 'react'
// @material-ui/core components
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import Select from '@mui/material/Select'
// import Icon from '@mui/material/Icon'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import FormControlLabel from '@mui/material/FormControlLabel'

// Material Dashboard 2 PRO React components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'
import MDSnackbar from 'components/MDSnackbar'

// zerouno
import glsService from 'services/gls.service'
import { useForm, Controller } from 'react-hook-form'
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom'
import ZUTitleForm from 'components/ZeroUno/ZUTitleForm'
import utils from 'utils/utils'
import { DateTime } from 'luxon'

export default function GlsForm() {
  const { idObj } = useParams()
  const { idDdt } = useParams()
  const [obj, setObj] = React.useState({})
  const [showSnackbar, setShowSnackbar] = React.useState({
    sh: false,
    msg: '',
    color: 'success'
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue
  } = useForm()
  const navigate = useNavigate()
  const pickup = new URLSearchParams(useLocation().search).get('pickup')

  useEffect(() => {
    fetchObject()
  }, [])

  useEffect(() => {
    reset({ ...obj })
    setValue('dateShipping', DateTime.fromISO(obj.dateShipping).toISODate())
    setValue('datePickup', DateTime.fromISO(obj.datePickup).toISODate())
  }, [obj])

  const fetchObject = () => {
    if (idObj === 'new') {
      setObj({
        weight: 3.0,
        dateShipping: !pickup ? DateTime.now() : null,
        datePickup: pickup ? DateTime.now() : null,
        amountType: 'n',
        service01: 'n',
        service02: 'n',
        service03: 'n',
        service04: 'n',
        service05: 'n',
        service06: 'n',
        from01: 'n',
        from02: 'n',
        to01: 'n',
        to02: 'n',
        pickup: !!pickup
      })
    } else {
      glsService.get(idObj).then(
        response => {
          response.data.weight = utils.formatFloat(response.data.weight, 2)
          response.data.amount = utils.formatFloat(response.data.amount, 2)

          setObj(response.data)
        },
        error => {
          setShowSnackbar({
            sh: true,
            msg: error.response
              ? error.response.data.message
              : 'errore di ricezione dati',
            color: 'error'
          })
        }
      )
    }
  }

  const onSubmit = data => {
    if (!data.amount || data.amount === '') {
      data.amount = 0.0
    }
    data.amount = String(data.amount).replace(',', '.')
    data.weight = String(data.weight).replace(',', '.')
    if (data.service01 === 'n') {
      data.service01 = null
    }
    if (data.service02 === 'n') {
      data.service02 = null
    }
    if (data.service03 === 'n') {
      data.service03 = null
    }
    if (data.service04 === 'n') {
      data.service04 = null
    }
    if (data.service05 === 'n') {
      data.service05 = null
    }
    if (data.service06 === 'n') {
      data.service06 = null
    }
    if (data.from01 === 'n' || !data.morning) {
      data.from01 = null
    }
    if (data.to01 === 'n' || !data.morning) {
      data.to01 = null
    }
    if (data.from02 === 'n' || !data.afternoon) {
      data.from02 = null
    }
    if (data.to02 === 'n' || !data.afternoon) {
      data.to02 = null
    }

    if (idObj === 'new') {
      addObj(data)
    } else {
      data.id = obj.id
      updateObj(data, obj.id)
    }
  }

  // Add obj
  const addObj = data => {
    data.ddtId = idDdt
    data.number = '0000'
    glsService.create(data).then(
      response => {
        setShowSnackbar({
          sh: true,
          msg: 'operazione effettuata con successo',
          color: 'success'
        })
        navigate(`/ddts/${idDdt}`)
      },
      error => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  const updateObj = (data, id) => {
    glsService.update(id, data).then(
      response => {
        setShowSnackbar({
          sh: true,
          msg: 'operazione effettuata con successo',
          color: 'success'
        })
        navigate(`/ddts/${idDdt}`)
      },
      error => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={8}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Card>
                <ZUTitleForm
                  bgColor='info'
                  title='Spedizione / ritiro GLS'
                  icon='place'
                />

                <MDBox p={2}>
                  <Grid container spacing={2}>
                    {!pickup && (
                      <Grid item xs={12} sm={12} md={3}>
                        <MDInput
                          fullWidth
                          type='date'
                          required
                          label='data spedizione'
                          error={!!errors.dateShipping}
                          {...register('dateShipping', { required: true })}
                        />
                      </Grid>
                    )}
                    {pickup && (
                      <Grid item xs={12} sm={12} md={3}>
                        <MDInput
                          fullWidth
                          type='date'
                          required
                          label='data ritiro'
                          error={!!errors.datePickup}
                          {...register('datePickup', { required: true })}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} sm={12} md={3}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        label='peso'
                        defaultValue={obj.weight}
                        {...register('weight', {
                          required: true,
                          pattern: utils.floatPattern
                        })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} />
                    {!pickup && (
                      <>
                        <Grid item xs={12} sm={12} md={6}>
                          <MDInput
                            fullWidth
                            type='text'
                            required={false}
                            label='contrassegno'
                            defaultValue={obj.amount}
                            {...register('amount', {
                              required: false,
                              pattern: utils.floatPattern
                            })}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          {obj.amountType && (
                            <FormControl
                              fullWidth
                              required={false}
                              error={!!errors.amountType}
                            >
                              <InputLabel>modalità di incasso</InputLabel>
                              <Select
                                label='modalità di incasso'
                                defaultValue={obj.amountType}
                                {...register('amountType', { required: false })}
                              >
                                <MenuItem key='CONT' value='CONT'>
                                  Contanti
                                </MenuItem>
                                <MenuItem key='AB' value='AB'>
                                  Assegno bancario
                                </MenuItem>
                              </Select>
                            </FormControl>
                          )}
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12} sm={12} md={6}>
                      {obj.service01 && (
                        <FormControl
                          fullWidth
                          required={false}
                          error={!!errors.service01}
                        >
                          <InputLabel>servizi accessori</InputLabel>
                          <Select
                            label='servizi accessori'
                            defaultValue={obj.service01}
                            {...register('service01', { required: false })}
                          >
                            <MenuItem key='n' value=''>
                              Seleziona
                            </MenuItem>
                            <MenuItem key='22' value='22'>
                              22-EXPRESS12
                            </MenuItem>
                            <MenuItem key='01' value='01'>
                              01-ENTRO ORE 12
                            </MenuItem>
                            <MenuItem key='02' value='02'>
                              02-ORA FISSA
                            </MenuItem>
                            <MenuItem key='03' value='03'>
                              03-ANTICIPATO
                            </MenuItem>
                            <MenuItem key='05' value='05'>
                              05-CONSEGNA AL PIANO
                            </MenuItem>
                            <MenuItem key='25' value='25'>
                              25-PREAVVISO TELEFONICO
                            </MenuItem>
                            <MenuItem key='06' value='06'>
                              06-MEZZO IDONEO
                            </MenuItem>
                            <MenuItem key='36' value='36'>
                              36-SATURDAY EXPRESS
                            </MenuItem>
                            <MenuItem key='08' value='08'>
                              08-VERIFICA CONTENUTO
                            </MenuItem>
                            <MenuItem key='23' value='23'>
                              23-DOCUMENT RETURN
                            </MenuItem>
                            <MenuItem key='24' value='24'>
                              24-EXCHANGE
                            </MenuItem>
                            <MenuItem key='27' value='27'>
                              27-TIMBRO
                            </MenuItem>
                            <MenuItem key='28' value='28'>
                              28-E-ROD
                            </MenuItem>
                            <MenuItem key='30' value='30'>
                              30-GDO
                            </MenuItem>
                            <MenuItem key='31' value='31'>
                              31-FLEX DELIVERY
                            </MenuItem>
                            <MenuItem key='32' value='32'>
                              32-IDENT PIN
                            </MenuItem>
                            <MenuItem key='34' value='34'>
                              34-GDO (ENTRO IL)
                            </MenuItem>
                            <MenuItem key='35' value='35'>
                              35-FOOD SERVICE
                            </MenuItem>
                            <MenuItem key='38' value='38'>
                              38-HEALTHCARE
                            </MenuItem>
                            <MenuItem key='14' value='14'>
                              14-ENTRO ORE 13 VENEZIA LAGUNA
                            </MenuItem>
                            <MenuItem key='15' value='15'>
                              15-MEZZO IDONEO VENEZIA LAGUNA
                            </MenuItem>
                            <MenuItem key='16' value='16'>
                              16-SCALO FLUVIALE VENEZIA
                            </MenuItem>
                            <MenuItem key='21' value='21'>
                              21-SERVIZIO AL SABATO VENEZIA
                            </MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <MDInput
                        fullWidth
                        type='text'
                        label='note'
                        error={!!errors.serviceNotes01}
                        {...register('serviceNotes01')}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      {obj.service02 && (
                        <FormControl
                          fullWidth
                          required={false}
                          error={!!errors.service02}
                        >
                          <InputLabel>servizi accessori</InputLabel>
                          <Select
                            label='servizi accessori'
                            defaultValue={obj.service02}
                            {...register('service02', { required: false })}
                          >
                            <MenuItem key='n' value=''>
                              Seleziona
                            </MenuItem>
                            <MenuItem key='22' value='22'>
                              22-EXPRESS12
                            </MenuItem>
                            <MenuItem key='01' value='01'>
                              01-ENTRO ORE 12
                            </MenuItem>
                            <MenuItem key='02' value='02'>
                              02-ORA FISSA
                            </MenuItem>
                            <MenuItem key='03' value='03'>
                              03-ANTICIPATO
                            </MenuItem>
                            <MenuItem key='05' value='05'>
                              05-CONSEGNA AL PIANO
                            </MenuItem>
                            <MenuItem key='25' value='25'>
                              25-PREAVVISO TELEFONICO
                            </MenuItem>
                            <MenuItem key='06' value='06'>
                              06-MEZZO IDONEO
                            </MenuItem>
                            <MenuItem key='36' value='36'>
                              36-SATURDAY EXPRESS
                            </MenuItem>
                            <MenuItem key='08' value='08'>
                              08-VERIFICA CONTENUTO
                            </MenuItem>
                            <MenuItem key='23' value='23'>
                              23-DOCUMENT RETURN
                            </MenuItem>
                            <MenuItem key='24' value='24'>
                              24-EXCHANGE
                            </MenuItem>
                            <MenuItem key='27' value='27'>
                              27-TIMBRO
                            </MenuItem>
                            <MenuItem key='28' value='28'>
                              28-E-ROD
                            </MenuItem>
                            <MenuItem key='30' value='30'>
                              30-GDO
                            </MenuItem>
                            <MenuItem key='31' value='31'>
                              31-FLEX DELIVERY
                            </MenuItem>
                            <MenuItem key='32' value='32'>
                              32-IDENT PIN
                            </MenuItem>
                            <MenuItem key='34' value='34'>
                              34-GDO (ENTRO IL)
                            </MenuItem>
                            <MenuItem key='35' value='35'>
                              35-FOOD SERVICE
                            </MenuItem>
                            <MenuItem key='38' value='38'>
                              38-HEALTHCARE
                            </MenuItem>
                            <MenuItem key='14' value='14'>
                              14-ENTRO ORE 13 VENEZIA LAGUNA
                            </MenuItem>
                            <MenuItem key='15' value='15'>
                              15-MEZZO IDONEO VENEZIA LAGUNA
                            </MenuItem>
                            <MenuItem key='16' value='16'>
                              16-SCALO FLUVIALE VENEZIA
                            </MenuItem>
                            <MenuItem key='21' value='21'>
                              21-SERVIZIO AL SABATO VENEZIA
                            </MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <MDInput
                        fullWidth
                        type='text'
                        label='note'
                        error={!!errors.serviceNotes02}
                        {...register('serviceNotes02')}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      {obj.service03 && (
                        <FormControl
                          fullWidth
                          required={false}
                          error={!!errors.service03}
                        >
                          <InputLabel>servizi accessori</InputLabel>
                          <Select
                            label='servizi accessori'
                            defaultValue={obj.service03}
                            {...register('service03', { required: false })}
                          >
                            <MenuItem key='n' value=''>
                              Seleziona
                            </MenuItem>
                            <MenuItem key='22' value='22'>
                              22-EXPRESS12
                            </MenuItem>
                            <MenuItem key='01' value='01'>
                              01-ENTRO ORE 12
                            </MenuItem>
                            <MenuItem key='02' value='02'>
                              02-ORA FISSA
                            </MenuItem>
                            <MenuItem key='03' value='03'>
                              03-ANTICIPATO
                            </MenuItem>
                            <MenuItem key='05' value='05'>
                              05-CONSEGNA AL PIANO
                            </MenuItem>
                            <MenuItem key='25' value='25'>
                              25-PREAVVISO TELEFONICO
                            </MenuItem>
                            <MenuItem key='06' value='06'>
                              06-MEZZO IDONEO
                            </MenuItem>
                            <MenuItem key='36' value='36'>
                              36-SATURDAY EXPRESS
                            </MenuItem>
                            <MenuItem key='08' value='08'>
                              08-VERIFICA CONTENUTO
                            </MenuItem>
                            <MenuItem key='23' value='23'>
                              23-DOCUMENT RETURN
                            </MenuItem>
                            <MenuItem key='24' value='24'>
                              24-EXCHANGE
                            </MenuItem>
                            <MenuItem key='27' value='27'>
                              27-TIMBRO
                            </MenuItem>
                            <MenuItem key='28' value='28'>
                              28-E-ROD
                            </MenuItem>
                            <MenuItem key='30' value='30'>
                              30-GDO
                            </MenuItem>
                            <MenuItem key='31' value='31'>
                              31-FLEX DELIVERY
                            </MenuItem>
                            <MenuItem key='32' value='32'>
                              32-IDENT PIN
                            </MenuItem>
                            <MenuItem key='34' value='34'>
                              34-GDO (ENTRO IL)
                            </MenuItem>
                            <MenuItem key='35' value='35'>
                              35-FOOD SERVICE
                            </MenuItem>
                            <MenuItem key='38' value='38'>
                              38-HEALTHCARE
                            </MenuItem>
                            <MenuItem key='14' value='14'>
                              14-ENTRO ORE 13 VENEZIA LAGUNA
                            </MenuItem>
                            <MenuItem key='15' value='15'>
                              15-MEZZO IDONEO VENEZIA LAGUNA
                            </MenuItem>
                            <MenuItem key='16' value='16'>
                              16-SCALO FLUVIALE VENEZIA
                            </MenuItem>
                            <MenuItem key='21' value='21'>
                              21-SERVIZIO AL SABATO VENEZIA
                            </MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <MDInput
                        fullWidth
                        type='text'
                        label='note'
                        error={!!errors.serviceNotes03}
                        {...register('serviceNotes03')}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      {obj.service04 && (
                        <FormControl
                          fullWidth
                          required={false}
                          error={!!errors.service04}
                        >
                          <InputLabel>servizi accessori</InputLabel>
                          <Select
                            label='servizi accessori'
                            defaultValue={obj.service04}
                            {...register('service04', { required: false })}
                          >
                            <MenuItem key='n' value=''>
                              Seleziona
                            </MenuItem>
                            <MenuItem key='22' value='22'>
                              22-EXPRESS12
                            </MenuItem>
                            <MenuItem key='01' value='01'>
                              01-ENTRO ORE 12
                            </MenuItem>
                            <MenuItem key='02' value='02'>
                              02-ORA FISSA
                            </MenuItem>
                            <MenuItem key='03' value='03'>
                              03-ANTICIPATO
                            </MenuItem>
                            <MenuItem key='05' value='05'>
                              05-CONSEGNA AL PIANO
                            </MenuItem>
                            <MenuItem key='25' value='25'>
                              25-PREAVVISO TELEFONICO
                            </MenuItem>
                            <MenuItem key='06' value='06'>
                              06-MEZZO IDONEO
                            </MenuItem>
                            <MenuItem key='36' value='36'>
                              36-SATURDAY EXPRESS
                            </MenuItem>
                            <MenuItem key='08' value='08'>
                              08-VERIFICA CONTENUTO
                            </MenuItem>
                            <MenuItem key='23' value='23'>
                              23-DOCUMENT RETURN
                            </MenuItem>
                            <MenuItem key='24' value='24'>
                              24-EXCHANGE
                            </MenuItem>
                            <MenuItem key='27' value='27'>
                              27-TIMBRO
                            </MenuItem>
                            <MenuItem key='28' value='28'>
                              28-E-ROD
                            </MenuItem>
                            <MenuItem key='30' value='30'>
                              30-GDO
                            </MenuItem>
                            <MenuItem key='31' value='31'>
                              31-FLEX DELIVERY
                            </MenuItem>
                            <MenuItem key='32' value='32'>
                              32-IDENT PIN
                            </MenuItem>
                            <MenuItem key='34' value='34'>
                              34-GDO (ENTRO IL)
                            </MenuItem>
                            <MenuItem key='35' value='35'>
                              35-FOOD SERVICE
                            </MenuItem>
                            <MenuItem key='38' value='38'>
                              38-HEALTHCARE
                            </MenuItem>
                            <MenuItem key='14' value='14'>
                              14-ENTRO ORE 13 VENEZIA LAGUNA
                            </MenuItem>
                            <MenuItem key='15' value='15'>
                              15-MEZZO IDONEO VENEZIA LAGUNA
                            </MenuItem>
                            <MenuItem key='16' value='16'>
                              16-SCALO FLUVIALE VENEZIA
                            </MenuItem>
                            <MenuItem key='21' value='21'>
                              21-SERVIZIO AL SABATO VENEZIA
                            </MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <MDInput
                        fullWidth
                        type='text'
                        label='note'
                        error={!!errors.serviceNotes04}
                        {...register('serviceNotes04')}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      {obj.service05 && (
                        <FormControl
                          fullWidth
                          required={false}
                          error={!!errors.service05}
                        >
                          <InputLabel>servizi accessori</InputLabel>
                          <Select
                            label='servizi accessori'
                            defaultValue={obj.service05}
                            {...register('service05', { required: false })}
                          >
                            <MenuItem key='n' value=''>
                              Seleziona
                            </MenuItem>
                            <MenuItem key='22' value='22'>
                              22-EXPRESS12
                            </MenuItem>
                            <MenuItem key='01' value='01'>
                              01-ENTRO ORE 12
                            </MenuItem>
                            <MenuItem key='02' value='02'>
                              02-ORA FISSA
                            </MenuItem>
                            <MenuItem key='03' value='03'>
                              03-ANTICIPATO
                            </MenuItem>
                            <MenuItem key='05' value='05'>
                              05-CONSEGNA AL PIANO
                            </MenuItem>
                            <MenuItem key='25' value='25'>
                              25-PREAVVISO TELEFONICO
                            </MenuItem>
                            <MenuItem key='06' value='06'>
                              06-MEZZO IDONEO
                            </MenuItem>
                            <MenuItem key='36' value='36'>
                              36-SATURDAY EXPRESS
                            </MenuItem>
                            <MenuItem key='08' value='08'>
                              08-VERIFICA CONTENUTO
                            </MenuItem>
                            <MenuItem key='23' value='23'>
                              23-DOCUMENT RETURN
                            </MenuItem>
                            <MenuItem key='24' value='24'>
                              24-EXCHANGE
                            </MenuItem>
                            <MenuItem key='27' value='27'>
                              27-TIMBRO
                            </MenuItem>
                            <MenuItem key='28' value='28'>
                              28-E-ROD
                            </MenuItem>
                            <MenuItem key='30' value='30'>
                              30-GDO
                            </MenuItem>
                            <MenuItem key='31' value='31'>
                              31-FLEX DELIVERY
                            </MenuItem>
                            <MenuItem key='32' value='32'>
                              32-IDENT PIN
                            </MenuItem>
                            <MenuItem key='34' value='34'>
                              34-GDO (ENTRO IL)
                            </MenuItem>
                            <MenuItem key='35' value='35'>
                              35-FOOD SERVICE
                            </MenuItem>
                            <MenuItem key='38' value='38'>
                              38-HEALTHCARE
                            </MenuItem>
                            <MenuItem key='14' value='14'>
                              14-ENTRO ORE 13 VENEZIA LAGUNA
                            </MenuItem>
                            <MenuItem key='15' value='15'>
                              15-MEZZO IDONEO VENEZIA LAGUNA
                            </MenuItem>
                            <MenuItem key='16' value='16'>
                              16-SCALO FLUVIALE VENEZIA
                            </MenuItem>
                            <MenuItem key='21' value='21'>
                              21-SERVIZIO AL SABATO VENEZIA
                            </MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <MDInput
                        fullWidth
                        type='text'
                        label='note'
                        error={!!errors.serviceNotes05}
                        {...register('serviceNotes05')}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      {obj.service06 && (
                        <FormControl
                          fullWidth
                          required={false}
                          error={!!errors.service06}
                        >
                          <InputLabel>servizi accessori</InputLabel>
                          <Select
                            label='servizi accessori'
                            defaultValue={obj.service06}
                            {...register('service06', { required: false })}
                          >
                            <MenuItem key='n' value=''>
                              Seleziona
                            </MenuItem>
                            <MenuItem key='22' value='22'>
                              22-EXPRESS12
                            </MenuItem>
                            <MenuItem key='01' value='01'>
                              01-ENTRO ORE 12
                            </MenuItem>
                            <MenuItem key='02' value='02'>
                              02-ORA FISSA
                            </MenuItem>
                            <MenuItem key='03' value='03'>
                              03-ANTICIPATO
                            </MenuItem>
                            <MenuItem key='05' value='05'>
                              05-CONSEGNA AL PIANO
                            </MenuItem>
                            <MenuItem key='25' value='25'>
                              25-PREAVVISO TELEFONICO
                            </MenuItem>
                            <MenuItem key='06' value='06'>
                              06-MEZZO IDONEO
                            </MenuItem>
                            <MenuItem key='36' value='36'>
                              36-SATURDAY EXPRESS
                            </MenuItem>
                            <MenuItem key='08' value='08'>
                              08-VERIFICA CONTENUTO
                            </MenuItem>
                            <MenuItem key='23' value='23'>
                              23-DOCUMENT RETURN
                            </MenuItem>
                            <MenuItem key='24' value='24'>
                              24-EXCHANGE
                            </MenuItem>
                            <MenuItem key='27' value='27'>
                              27-TIMBRO
                            </MenuItem>
                            <MenuItem key='28' value='28'>
                              28-E-ROD
                            </MenuItem>
                            <MenuItem key='30' value='30'>
                              30-GDO
                            </MenuItem>
                            <MenuItem key='31' value='31'>
                              31-FLEX DELIVERY
                            </MenuItem>
                            <MenuItem key='32' value='32'>
                              32-IDENT PIN
                            </MenuItem>
                            <MenuItem key='34' value='34'>
                              34-GDO (ENTRO IL)
                            </MenuItem>
                            <MenuItem key='35' value='35'>
                              35-FOOD SERVICE
                            </MenuItem>
                            <MenuItem key='38' value='38'>
                              38-HEALTHCARE
                            </MenuItem>
                            <MenuItem key='14' value='14'>
                              14-ENTRO ORE 13 VENEZIA LAGUNA
                            </MenuItem>
                            <MenuItem key='15' value='15'>
                              15-MEZZO IDONEO VENEZIA LAGUNA
                            </MenuItem>
                            <MenuItem key='16' value='16'>
                              16-SCALO FLUVIALE VENEZIA
                            </MenuItem>
                            <MenuItem key='21' value='21'>
                              21-SERVIZIO AL SABATO VENEZIA
                            </MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <MDInput
                        fullWidth
                        type='text'
                        label='note'
                        error={!!errors.serviceNotes06}
                        {...register('serviceNotes06')}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <MDInput
                        fullWidth
                        type='text'
                        label='note'
                        error={!!errors.notes01}
                        {...register('notes01')}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <MDInput
                        fullWidth
                        type='text'
                        label='note corriere'
                        error={!!errors.notes02}
                        {...register('notes02')}
                      />
                    </Grid>
                    {pickup && (
                      <>
                        <Grid item xs={12} sm={4} md={4}>
                          <Controller
                            control={control}
                            name='morning'
                            render={({ field: { value, onChange } }) => (
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={value || false}
                                    onChange={onChange}
                                  />
                                }
                                label='mattina'
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                          {obj.from01 && (
                            <FormControl
                              fullWidth
                              required={false}
                              error={!!errors.from01}
                            >
                              <InputLabel>dalle</InputLabel>
                              <Select
                                label='dalle'
                                defaultValue={obj.from01}
                                {...register('from01', { required: false })}
                              >
                                <MenuItem key='08' value='08'>
                                  08:00
                                </MenuItem>
                                <MenuItem key='09' value='09'>
                                  09:00
                                </MenuItem>
                                <MenuItem key='10' value='10'>
                                  10:00
                                </MenuItem>
                                <MenuItem key='11' value='11'>
                                  11:00
                                </MenuItem>
                                <MenuItem key='12' value='12'>
                                  12:00
                                </MenuItem>
                              </Select>
                            </FormControl>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                          {obj.to01 && (
                            <FormControl
                              fullWidth
                              required={false}
                              error={!!errors.to01}
                            >
                              <InputLabel>alle</InputLabel>
                              <Select
                                label='alle'
                                defaultValue={obj.to01}
                                {...register('to01', { required: false })}
                              >
                                <MenuItem key='09' value='09'>
                                  09:00
                                </MenuItem>
                                <MenuItem key='10' value='10'>
                                  10:00
                                </MenuItem>
                                <MenuItem key='11' value='11'>
                                  11:00
                                </MenuItem>
                                <MenuItem key='12' value='12'>
                                  12:00
                                </MenuItem>
                                <MenuItem key='13' value='13'>
                                  13:00
                                </MenuItem>
                              </Select>
                            </FormControl>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                          <Controller
                            control={control}
                            name='afternoon'
                            render={({ field: { value, onChange } }) => (
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={value || false}
                                    onChange={onChange}
                                  />
                                }
                                label='pomeriggio'
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                          {obj.from02 && (
                            <FormControl
                              fullWidth
                              required={false}
                              error={!!errors.from02}
                            >
                              <InputLabel>dalle</InputLabel>
                              <Select
                                label='dalle'
                                defaultValue={obj.from02}
                                {...register('from02', { required: false })}
                              >
                                <MenuItem key='13' value='13'>
                                  13:00
                                </MenuItem>
                                <MenuItem key='14' value='14'>
                                  14:00
                                </MenuItem>
                                <MenuItem key='15' value='15'>
                                  15:00
                                </MenuItem>
                                <MenuItem key='16' value='16'>
                                  16:00
                                </MenuItem>
                                <MenuItem key='17' value='17'>
                                  17:00
                                </MenuItem>
                                <MenuItem key='18' value='18'>
                                  18:00
                                </MenuItem>
                              </Select>
                            </FormControl>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                          {obj.to02 && (
                            <FormControl
                              fullWidth
                              required={false}
                              error={!!errors.to02}
                            >
                              <InputLabel>alle</InputLabel>
                              <Select
                                label='alle'
                                defaultValue={obj.to02}
                                {...register('to02', { required: false })}
                              >
                                <MenuItem key='14' value='14'>
                                  14:00
                                </MenuItem>
                                <MenuItem key='15' value='15'>
                                  15:00
                                </MenuItem>
                                <MenuItem key='16' value='16'>
                                  16:00
                                </MenuItem>
                                <MenuItem key='17' value='17'>
                                  17:00
                                </MenuItem>
                                <MenuItem key='18' value='18'>
                                  18:00
                                </MenuItem>
                                <MenuItem key='19' value='19'>
                                  19:00
                                </MenuItem>
                              </Select>
                            </FormControl>
                          )}
                        </Grid>
                      </>
                    )}
                  </Grid>
                </MDBox>
                <MDBox p={2}>
                  <MDButton
                    variant='gradient'
                    color='secondary'
                    component={Link}
                    to={`/ddts/${idDdt}`}
                  >
                    Indietro
                  </MDButton>
                  {idObj === 'new' && (
                    <MDButton
                      type='submit'
                      variant='gradient'
                      color='success'
                      sx={{ ml: 1 }}
                    >
                      Salva
                    </MDButton>
                  )}
                </MDBox>
              </Card>
            </form>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Card>
              <MDBox p={2}>
                <Stack spacing={2} />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content={showSnackbar.msg}
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />
    </DashboardLayout>
  )
}
