'use strict'

import http from './http-common'
import authHeader from './auth-header'
import useSWR, { mutate, useSWRConfig } from 'swr'

const findAll = () => {
  return http.axiosClient.get('/contacts', { headers: authHeader() })
}

const findByRegistry = (idRegistry) => {
  return http.axiosClient.get(`/registries/${idRegistry}/contacts`, { headers: authHeader() })
}

// const get = (customerId, id) => {
//   if (id === 'new') {
//     return {
//       data: {},
//       isLoading: false
//     }
//   } else {
//     const { data, error } = useSWR([`/registries/${customerId}/addresses/${id}`, { headers: authHeader() }], http.fetcherAuth)
//     return {
//       data: data || {},
//       isLoading: !error && !data,
//       error: error
//     }
//   }
// }

// const getAll = (customerId) => {
//   return http.axiosClient.get(`/registries/${customerId}/addresses`, {
//     headers: authHeader()
//   })
// }

const get = (id) => {
  return http.axiosClient.get(`/contacts/${id}`, {
    headers: authHeader()
  })
}

const create = (data, idRegistry) => {
  return http.axiosClient.post(`/registries/${idRegistry}/contacts`, data, {
    headers: authHeader()
  })
}

const update = (id, data) => {
  return http.axiosClient.put(`/contacts/${id}`, data, {
    headers: authHeader()
  })
}

const remove = (id) => {
  return http.axiosClient.delete(`/contacts/${id}`, {
    headers: authHeader()
  })
}

const refresh = () => {
  mutate(['/contacts/', {
    headers: authHeader()
  }])
}

export default {
  findAll,
  findByRegistry,
  get,
  create,
  update,
  remove,
  refresh
}
