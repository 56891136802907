'use strict'

import http from './http-common'
import authHeader from './auth-header'
import useSWR, { mutate, useSWRConfig } from 'swr'

// const findAll = (projectId) => {
//   return http.axiosClient.get(`/projects/${projectId}/attachments`, { headers: authHeader() })
// }

const findAll = (projectId) => {
  const url = `/projects/${projectId}/attachments`
  const { data, error } = useSWR([url, { headers: authHeader() }], http.fetcherAuth)
  return {
    data: data || [],
    isLoading: !error && !data,
    error: error
  }
}

const findById = (projectId, id) => {
  return http.axiosClient.get(`/projects/${projectId}/attachments/${id}`, { headers: authHeader() })
}

const create = (projectId, data) => {
  return http.axiosClient.post(`/projects/${projectId}/attachments`, data, {
    headers: authHeader()
  })
}

const update = (projectId, id, data) => {
  return http.axiosClient.patch(`/projects/${projectId}/attachments/${id}`, data, {
    headers: authHeader()
  })
}

const remove = (projectId, id) => {
  return http.axiosClient.delete(`/projects/${projectId}/attachments/${id}`, { headers: authHeader() })
}

const refresh = (projectId) => {
  const url = `/projects/${projectId}/attachments`
  mutate([url, {
    headers: authHeader()
  }])
}

const upload = (data, projectId) => {
  return http.axiosClient.post(`/projects/${projectId}/attachments/upload`, data, { headers: authHeader() })
}

const download = (file, projectId) => {
  return http.axiosClient.get(`/projects/${projectId}/attachments/download/${file}`, { headers: authHeader() })
}

export default {
  findAll,
  findById,
  create,
  update,
  remove,
  refresh,
  upload,
  download
}
