const prod = {
  SERVER_URL: 'https://assistenza.comunica.live/api',
  SAMPLE_DATA: false,
  APP_NAME: 'comunicaTT'
}

const dev = {
  SERVER_URL: 'http://localhost:8080/api',
  SAMPLE_DATA: false,
  APP_NAME: 'comunicaTT dev.'

}

export const config = process.env.NODE_ENV === 'production' ? prod : dev
