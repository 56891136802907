'use strict'
import { useEffect, useContext, useState } from 'react'
// @mui material components
import Card from '@mui/material/Card'
import Icon from '@mui/material/Icon'
import Grid from '@mui/material/Grid'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
// import Menu from '@mui/material/Menu'
// import MenuItem from '@mui/material/MenuItem'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
// import MDTypography from 'components/MDTypography'
import MDSnackbar from 'components/MDSnackbar'
import MDButton from 'components/MDButton'
// import MDDropdown from 'components/MDDropdown'

// Material Dashboard 2 PRO React example components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'
import ZuDataTable from 'components/ZeroUno/ZuDataTable'

// zerouno
import registryService from 'services/registry.service'
import mainService from 'services/contact.service'
import { useParams, Link } from 'react-router-dom'
import ZUTitleForm from 'components/ZeroUno/ZUTitleForm'
import utils from 'utils/utils.js'
import RegistryNav from './RegistryNav'

export default function ContactTable() {
  const [objId, setObjId] = useState(0)
  const { idRegistry } = useParams()
  const [obj, setObj] = useState([])
  const [objects, setObjects] = useState([])
  const [showSnackbar, setShowSnackbar] = useState({
    sh: false,
    msg: '',
    color: 'success'
  })
  const [openConfirm, setOpenConfirm] = useState(false)

  useEffect(() => {
    fetchObjects()
  }, [])

  const fetchObjects = () => {
    if (idRegistry) {
      registryService
        .findById(idRegistry)
        .then((response) => {
          setObj(response.data)
        })
        .then(() => {
          return mainService.findByRegistry(idRegistry)
        })
        .then((response2) => {
          setObjects(response2.data)
        })
        .catch((error) => {
          setShowSnackbar({
            sh: true,
            msg: error.response
              ? error.response.data.message
              : 'errore di ricezione dati',
            color: 'error'
          })
        })
    } else {
      mainService
        .findAll()
        .then((response) => {
          setObjects(response.data)
        })
        .catch((error) => {
          setShowSnackbar({
            sh: true,
            msg: error.response
              ? error.response.data.message
              : 'errore di ricezione dati',
            color: 'error'
          })
        })
    }
  }

  // Delete
  const deleteObject = () => {
    setOpenConfirm(false)
    mainService.remove(objId).then(
      (response) => {
        setShowSnackbar({
          sh: true,
          msg: 'operazione effettuata con successo',
          color: 'success'
        })
        fetchObjects()
      },
      (error) => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  const columns = [
    {
      header: 'Nome',
      accessorFn: (row) => row.user?.name + ' ' + row.user?.surname
    },
    {
      header: 'Ruolo',
      accessorKey: 'notes'
    },
    {
      header: 'email',
      accessorFn: (row) => row.user?.email.toLowerCase()
    },
    {
      header: 'telefono',
      accessorFn: (row) => (row.user?.phone ? row.user.phone.toLowerCase() : '')
    },
    {
      header: 'cellulare',
      accessorFn: (row) =>
        row.user?.mobile ? row.user.mobile.toLowerCase() : ''
    },
    {
      header: 'ticket',
      accessorFn: (row) =>
        row.ticketPermissionId === 11
          ? 'tutti'
          : row.ticketPermissionId === 12
            ? 'solo i propri'
            : 'nessuno'
    },
    {
      header: 'azioni',
      enableSorting: false,
      enableColumnFilter: false,
      width: 100,
      Cell: ({ row }) => (
        <>
          <MDButton
            color='info'
            size='small'
            iconOnly
            component={Link}
            to={
              '/contacts/' +
              row.original.id +
              (idRegistry ? `?idRegistry=${idRegistry}` : '')
            }
            sx={{ mr: 1 }}
          >
            <Icon>edit</Icon>
          </MDButton>
          <MDButton
            iconOnly
            color='error'
            size='small'
            onClick={() => {
              setObjId(row.original.id)
              setOpenConfirm(true)
            }}
          >
            <Icon>delete</Icon>
          </MDButton>
        </>
      )
    }
  ]

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={idRegistry ? 10 : 12}>
            <Card>
              <ZUTitleForm bgColor='info' title='Contatti' icon='list_alt' />
              {idRegistry && (
                <MDBox p={3} lineHeight={1}>
                  <MDButton
                    color='info'
                    component={Link}
                    to={`/contacts/new?idRegistry=${idRegistry}`}
                    m={1}
                  >
                    nuovo
                  </MDButton>
                </MDBox>
              )}
              {!idRegistry && (
                <MDBox p={3} lineHeight={1}>
                  <MDButton
                    color='secondary'
                    component={Link}
                    to='/contacts'
                    sx={{ m: 1 }}
                  >
                    tutti i contatti
                  </MDButton>
                  <MDButton
                    color='info'
                    component={Link}
                    to='/contacts/new'
                    m={1}
                  >
                    nuovo
                  </MDButton>
                </MDBox>
              )}

              <ZuDataTable table={{ columns: columns, rows: objects }} />
            </Card>
          </Grid>
          {idRegistry && (
            <Grid item xs={12} sm={12} md={2}>
              <RegistryNav id={idRegistry} />
            </Grid>
          )}
        </Grid>
      </MDBox>
      <Footer />
      <Dialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          Confermi eliminazione?
        </DialogTitle>
        <DialogActions>
          <MDButton onClick={() => setOpenConfirm(false)}>Annulla</MDButton>
          <MDButton onClick={deleteObject} autoFocus>
            Conferma
          </MDButton>
        </DialogActions>
      </Dialog>
      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content={showSnackbar.msg}
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />
    </DashboardLayout>
  )
}
