'use strict'

import http from './http-common'
import authHeader from './auth-header'
import useSWR, { mutate, useSWRConfig } from 'swr'

const findAll = (registryId) => {
  const url = `/registries/${registryId}/documents`
  const { data, error } = useSWR([url, { headers: authHeader() }], http.fetcherAuth)
  return {
    data: data || [],
    isLoading: !error && !data,
    error: error
  }
}

const findById = (registryId, id) => {
  return http.axiosClient.get(`/registries/${registryId}/documents/${id}`, {
    headers: authHeader()
  })
}

const create = (registryId, data) => {
  return http.axiosClient.post(`/registries/${registryId}/documents`, data, {
    headers: authHeader()
  })
}

const update = (registryId, id, data) => {
  return http.axiosClient.patch(`/registries/${registryId}/documents/${id}`, data, {
    headers: authHeader()
  })
}

const remove = (registryId, id) => {
  return http.axiosClient.delete(`/registries/${registryId}/documents/${id}`, {
    headers: authHeader()
  })
}
const refresh = (registryId) => {
  const url = `/registries/${registryId}/documents`
  mutate([url, {
    headers: authHeader()
  }])
}

const upload = (data, registryId) => {
  return http.axiosClient.post(`/registries/${registryId}/documents/upload`, data, { headers: authHeader() })
}

const download = (file, registryId) => {
  return http.axiosClient.get(`/registries/${registryId}/documents/download/${file}`, { headers: authHeader() })
}

export default {
  findAll,
  findById,
  create,
  update,
  remove,
  upload,
  download,
  refresh
}
