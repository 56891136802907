'use strict'

import http from './http-common'
import authHeader from './auth-header'
import useSWR, { mutate, useSWRConfig } from 'swr'

const findAll = (registryId) => {
  const url = registryId ? `/registries/${registryId}/projects` : '/projects'
  const { data, error } = useSWR([url, { headers: authHeader() }], http.fetcherAuth)
  return {
    data: data || [],
    isLoading: !error && !data,
    error: error
  }
}

const findById = (id) => {
  return http.axiosClient.get(`/projects/${id}`, {
    headers: authHeader()
  })
}

const create = (registryId, data) => {
  return http.axiosClient.post(`/registries/${registryId}/projects`, data, {
    headers: authHeader()
  })
}

const update = (id, data) => {
  return http.axiosClient.patch(`/projects/${id}`, data, {
    headers: authHeader()
  })
}

const remove = (registryId, id) => {
  return http.axiosClient.delete(`/projects/${id}`, {
    headers: authHeader()
  })
}
const refresh = (registryId) => {
  const url = registryId ? `/registries/${registryId}/projects` : '/projects'
  mutate([url, {
    headers: authHeader()
  }])
}

const findByRegistry = (registryId) => {
  return http.axiosClient.get(`/registries/${registryId}/projects`, {
    headers: authHeader()
  })
}

export default {
  findAll,
  findById,
  create,
  update,
  remove,
  refresh,
  findByRegistry
}
